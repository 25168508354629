import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputBase,
  InputLabel,
  Snackbar,
  Stack,
  Typography,
  styled,
  Link,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { LoadingButton, MobileDatePicker } from '@mui/lab';
import { useParams } from 'react-router';
import { endOfYear, subYears } from 'date-fns';
import Iconify from '../../../../components/Iconify';
import PhonenumberTextField from '../../../../components/PhonenumberTextField';
import useLocales from '../../../../hooks/useLocales';
import useAuth from '../../../../hooks/useAuth';

import { useDispatch } from '../../../../redux/store';
import { openLoginSignup } from '../../../../redux/slices/authPages';
import firebaseUtil from '../../../../utils/firebaseUtil';
import { getOtpErrorMessage } from '../../../../utils/formatString';
import RegisterOTPForm from './RegisterOTPForm';

import palette from '../../../../theme/palette';
import { fixMYPrefix } from '../../../../utils/formatNumber';


const StyledIconify = styled(Iconify)(({ theme }) => ({
  height: 24,
  width: 24,
  color: palette.visitor.icon,
}));

const InputContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '5px',
  height: '44px',
  padding: '8.5px 13px',
  backgroundColor: '#000',
}));

const InputBorder = styled('div')(() => ({
  height: 'fit-content',
  position: 'relative',
  padding: 2,
  flex: 1,
  borderRadius: '8px',
  background: `linear-gradient(90deg, #BC8001 0%, #C8941C 17.62%, #FAEB9A 26.88%, #C8941C 32.84%, #C8941C 38.5%, #FAEB9A 43.4%, #FAEB9A 52.96%, #C8941C 59.75%, #C8941C 63.11%, #FAEB9A 72.02%, #C8941C 79.16%, #BC8001 100%)`,
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  marginLeft: 8,
  fontSize: '15px',
  fontWeight: 400,
  fontFamily: 'sans-serif',
  flexGrow: 1,
  color: palette.visitor.input.color,
  input: {
    '&::placeholder': {
      opacity: 1,
      color: palette.visitor.input.placeholder,
    },
  },
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  fontSize: '.80rem',
  color: palette.visitor.errorText,
}));

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: '15px',
  fontFamily: 'sans-serif',
  fontWeight: 500,
  color: palette.visitor.dialog.otherText,
  cursor: 'pointer',
}));

const LabelText = styled(Typography)(({ theme }) => ({
  fontSize: '1.4rem',
  color: palette.visitor.dialog.title,
  fontWeight: 600,
}));

const BirthdayText = styled(Typography)(({ theme }) => ({
  fontSize: '.75rem',
  color: palette.visitor.dialog.otherText,
  fontWeight: 500,
}));

const RegisterButton = styled(LoadingButton)(({ theme }) => ({
  width: '100%',
  marginTop: 8,
  marginBottom: 8,
  background: palette.visitor.register.buttonBg,
  border: `2px solid ${palette.visitor.register.border}`,
  color: `${palette.visitor.register.color} !important`,
  '&:disabled': {
    filter: 'grayscale(0.8)',
  },
}));

const CustomHr = ({ style }) => (
  <hr style={{ borderTop: `1.5px solid ${palette.visitor.dialog.hr}`, borderBottom: 'none', height: 1, ...style }} />
);

const LinkText = styled(Link)({
  textDecoration: 'underline', 
  fontWeight: 600, 
  color: palette.visitor.link,
  cursor: 'pointer'
});

const _registerForm = {
  userUserName: '',
  userPassword: '',
  userPhoneNumber: '',
  userFullName: '',
  // userEmail: '',
  userBirthday: null,
  userReferralCode: '',
  captcha: '',
};

const Register = ({ setActiveTab, authData }) => {
  const dispatch = useDispatch();

  const params = useParams();

  const navigate = useNavigate();

  const { register } = useAuth();

  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const [registerForm, setRegisterForm] = useState(_registerForm);

  const [formError, setFormError] = useState({});

  const [tncConfirmed, setTncConfirmed] = useState(false);

  const [statusMessage, setStatusMessage] = useState({
    open: false,
    message: '',
  });

  const [validationErrors, setValidationErrors] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [verification, setVerification] = useState({
    number: null,
    id: null,
    otpSent: false,
    isVerified: false,
  });

  const platformText = useMemo(() => {
    return authData?.platform.charAt(0).toUpperCase() + authData?.platform.slice(1);
  }, [authData]);

  const isValidSubmit = useMemo(() => {
    if (authData) {
      return (
        !tncConfirmed ||
        // !registerForm?.userUserName ||
        // !registerForm?.captcha ||
        // !registerForm?.userEmail ||
        !registerForm.userFullName ||
        !registerForm?.userPhoneNumber ||
        !verification?.otp ||
        !verification?.id
      );
    }

    return (
      !tncConfirmed ||
      // !registerForm?.userUserName ||
      // !registerForm?.captcha ||
      // !registerForm?.userEmail ||
      !registerForm.userFullName ||
      !registerForm?.userPassword ||
      !registerForm?.userPhoneNumber
    );
  }, [registerForm, tncConfirmed, authData]);

  useEffect(() => {
    const _referralCode = localStorage.getItem(`referralCode-${params?.domain}`);

    if (_referralCode) {
      setRegisterForm((prevState) => ({ ...prevState, userReferralCode: _referralCode }));
    }

    // setTimeout(() => {
    //   loadCaptchaEnginge(4);
    // }, 1000);
  }, []);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setRegisterForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const verifyOtp = async () => {
    if (!isValid()) return;

    try {
      if (verification?.id) {
        await firebaseUtil.handleVerifyOTP(verification?.id, verification?.otp);
        setVerification((prev) => ({ ...prev, isVerified: true }));

        onSubmit();
      }
    } catch (error) {
      const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
      enqueueSnackbar(getOtpErrorMessage(translate, error), snackbarOptions);
      throw new Error(getOtpErrorMessage(translate, error));
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);

    const requestData = {
      'user-username': registerForm?.userPhoneNumber?.replace(/\D/g, ''),
      // 'user-username': registerForm?.userUserName.trim(),
      'user-password': registerForm?.userPassword.trim(),
      'user_profile-no_hp': fixMYPrefix(registerForm?.userPhoneNumber),
      'user-full_name': registerForm?.userFullName,
      // 'user-email': registerForm?.userEmail,
      // 'user_profile-birthday': moment(registerForm?.userBirthday).format('YYYY-MM-DD'),
      'user-referral_code': registerForm?.userReferralCode,
    };

    if (authData) {
      const _authData = JSON.parse(JSON.stringify(authData));
      delete _authData?.platform;
      requestData['user_third_party_login-platform'] = authData?.platform;

      requestData['user_third_party_login-data'] = JSON.stringify(_authData);
    }

    try {
      await register(requestData);
      const _referralCode = localStorage.getItem(`referralCode-${params?.domain}`);
      if (_referralCode) localStorage.removeItem(`referralCode-${params?.domain}`);
      dispatch(openLoginSignup({ open: false, isLogin: true }));
    } catch (err) {
      console.log(`Error: ${err.message}`);
      const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
      enqueueSnackbar(err.message, snackbarOptions);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('Enter key handler');
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [registerForm]);

  useEffect(() => {
    if (authData) {
      setRegisterForm((prev) => ({
        ...prev,
        userFullName: `${authData?.first_name || ''} ${authData?.last_name || ''}`,
        // userEmail: `${authData?.email || ''}`,
      }));
    }
  }, [authData]);

  const isValid = () => {
    let valid = true;
    const _error = {};

    // // username
    // if (registerForm?.userUserName?.trim()?.length < 3) {
    //   valid = false;
    //   _error.userUserName = translate('invalid_x_must_be_atleast_y_characters', { x: translate('username'), y: '3' });
    // } else {
    //   _error.userUserName = '';
    // }

    // password
    if (registerForm?.userPassword?.trim()?.length < 5 && !authData) {
      valid = false;
      _error.userPassword = translate('invalid_x_must_be_atleast_y_characters', { x: translate('password'), y: '5' });
    } else {
      _error.userPassword = '';
    }

    // fullname
    if (!registerForm?.userFullName.trim()) {
      valid = false;
      _error.userFullName = translate('invalid_x_must_be_atleast_y_characters', { x: translate('full_name'), y: '1' });
    } else {
      _error.userFullName = '';
    }

    // email
    /* if (!registerForm?.userEmail.match('[a-z0-9]+@[a-z]+.[a-z]{2,3}')) {
      valid = false;
      _error.userEmail = translate('invalid_x', { x: translate('email') });
    } else {
      _error.userEmail = '';
    } */

    // phone number
    if (!registerForm?.userPhoneNumber.trim()) {
      valid = false;
      _error.userPhoneNumber = translate('x_is_required', { x: translate('phone_number') });
    } else if (registerForm?.userPhoneNumber.trim().length < process.env.REACT_APP_MOBILENUMBER_MIN_LENGTH) {
      valid = false;
      _error.userPhoneNumber = translate(`invalid_x_must_be_atleast_y_characters`, {
        x: translate('phone_number'),
        y: process.env.REACT_APP_MOBILENUMBER_MIN_LENGTH,
      });
    } else if (registerForm?.userPhoneNumber.trim().length > process.env.REACT_APP_MOBILENUMBER_MAX_LENGTH) {
      valid = false;
      _error.userPhoneNumber = translate('phone_num_x_max', { x: process.env.REACT_APP_MOBILENUMBER_MAX_LENGTH });
    } else {
      _error.userPhoneNumber = '';
    }

    // birthday
    // if (!registerForm?.userBirthday) {
    //   valid = false;
    //   _error.userBirthday = translate('x_is_required', { x: translate('birthday') });
    // } else {
    //   _error.userBirthday = '';
    // }

    // if (validateCaptcha(registerForm?.captcha?.trim(), false) === true) {
    //   _error.userCaptcha = '';
    // } else {
    //   valid = false;
    //   _error.userCaptcha = translate('captcha_not_match');
    // }

    setFormError((prevState) => _error);

    return valid;
  };

  // const sendOtp = async () => {
  //   if (!registerForm?.userPhoneNumber) {
  //     enqueueSnackbar('Please enter a valid Phone Number', getSnackbarOption('error'));

  //     return;
  //   }

  //   try {
  //     //   await register(data.email, data.password, data.firstName, data.lastName);
  //     // console.log(data);
  //     const appVerifier = window.recaptchaVerifier;
  //     const _verificationId = await firebaseUtil.handleSendOTP(registerForm?.userPhoneNumber, appVerifier);

  //     setVerification((prevState) => ({
  //       ...prevState,
  //       otpSent: true,
  //       id: _verificationId,
  //     }));

  //     enqueueSnackbar('Verification Code Successfully Sent.', getSnackbarOption('success'));
  //   } catch (error) {
  //     console.error(error?.code);

  //     // reset();

  //     let errorMessage = error?.response?.data?.message || error?.response?.data || error.message;
  //     switch (error?.code) {
  //       case 'auth/invalid-verification-code':
  //         errorMessage = translate('invalid_verification');
  //         break;
  //       case 'auth/code-expired':
  //         errorMessage = translate('otp_expired');
  //         break;
  //       case 'auth/invalid-phone-number' || 'auth/too-many-requests':
  //         errorMessage = translate('invalid_x', { x: translate('phone_number') });
  //         break;
  //       case 'auth/captcha-check-failed':
  //         errorMessage = translate('captcha_check_failed');
  //         break;

  //       default:
  //         errorMessage = translate('Network Error');
  //         break;
  //     }
  //     setOtpError({ ...error, message: errorMessage });
  //   }
  // };

  function getSnackbarOption(variant) {
    return { variant, autoHideDuration: 5000 };
  }

  return (
    <Box>
      {validationErrors && (
        <Alert onClose={() => setValidationErrors(null)} severity="error" sx={{ width: '100%', my: '10px' }}>
          {
            <ul style={{ marginLeft: 10 }}>
              {validationErrors?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          }
        </Alert>
      )}
      {authData ? (
        <Alert sx={{ width: '100%', my: '10px' }}>
          {translate('linked_successfully_x', {
            x: platformText,
          })}
        </Alert>
      ) : (
        ''
      )}
      <LabelText>{translate('sign_up')}</LabelText>
      <CustomHr style={{ marginBottom: '12px' }} />

      <Box sx={{ mb: '10px' }}>
        <PhonenumberTextField
          name="userPhoneNumber"
          value={registerForm?.userPhoneNumber}
          onChange={(e) => setRegisterForm((prevState) => ({ ...prevState, userPhoneNumber: e }))}
          fullWidth
          sx={{ color: 'grey', borderColor: '#FF1500', fontSize: '15px' }}
        />
        {formError?.userPhoneNumber && (
          <span style={{ fontSize: '.80rem', color: '#FF1500' }}>{formError?.userPhoneNumber}</span>
        )}
      </Box>
      {/* <Box sx={{ mb: '10px' }}>
        <InputContainer direction="row">
          <StyledIconify icon={'mdi-light:account'} />
          <StyledInput
            name="userUserName"
            value={registerForm?.userUserName}
            onChange={(e) => handleFormChange(e)}
            placeholder={translate('please_input_your_x', { x: translate('username') })}
          />
        </InputContainer>
        {formError?.userUserName && <ErrorText>{formError?.userUserName}</ErrorText>}
      </Box>
      <Box sx={{ mb: '10px' }}>
        <InputContainer direction="row">
          <StyledIconify icon={'mdi-light:email'} />
          <StyledInput
            name="userEmail"
            value={registerForm?.userEmail}
            onChange={(e) => handleFormChange(e)}
            placeholder={translate('please_input_your_x', { x: translate('email') })}
          />
        </InputContainer>
        {formError?.userEmail && <ErrorText>{formError?.userEmail}</ErrorText>}
      </Box> */}

      <Box sx={{ mb: '10px' }}>
        <InputBorder>
          <InputContainer direction="row">
            <StyledIconify icon={'carbon:data-2'} />
            <StyledInput
              name="userFullName"
              value={registerForm?.userFullName}
              onChange={(e) => handleFormChange(e)}
              placeholder={translate('please_input_your_x', { x: translate('full_name') })}
            />
          </InputContainer>
        </InputBorder>
        <Typography
          sx={{ fontSize: '13px', fontStyle: 'italic', color: 'gold', textShadow: '1px 1px 0px red', fontWeight: 600 }}
        >
          {translate('fullname_note')}
        </Typography>
        {formError?.userFullName && <ErrorText>{formError?.userFullName}</ErrorText>}
      </Box>

      {/* <Box sx={{ mb: '10px' }}>
        <InputContainer direction="row">
          <StyledIconify icon={'clarity:calendar-line'} />
          <MobileDatePicker
            label={translate('birthday')}
            value={registerForm.userBirthday}
            inputFormat="dd/MM/yyyy"
            disableCloseOnSelect={false}
            onChange={(v) => setRegisterForm((prevState) => ({ ...prevState, userBirthday: v }))}
            renderInput={(params) => (
              <InputBase
                {...params}
                variant="outlined"
                size="small"
                // inputProps={{
                //   '&::placeholder': {
                //     opacity: 1,
                //     color: 'grey',
                //   },
                // }}
                placeholder={translate('please_input_your_x', { x: translate('birthday') })}
                sx={{
                  width: '100%',
                  ml: 1,
                  fontSize: '14px',
                  fontFamily: 'sans-serif',
                  color: palette.visitor.input.color,
                  '& input': {
                    color: `${palette.visitor.input.color} !important`,
                    '&::placeholder': {
                      opacity: 1,
                      color: `${palette.visitor.input.placeholder} !important`,
                    },
                  },
                }}
              />
            )}
            openTo="year"
            maxDate={subYears(endOfYear(new Date()), 18)}
          />
        </InputContainer>
        <BirthdayText>{translate('birthday_bonus')}</BirthdayText>
        {formError?.userBirthday && <ErrorText>{formError?.userBirthday}</ErrorText>}
      </Box> */}

      {!authData ? (
        <Box sx={{ mb: '10px' }}>
          <InputBorder>
            <InputContainer direction="row">
              <StyledIconify icon={'ph:lock-light'} />
              <StyledInput
                type="password"
                name="userPassword"
                value={registerForm?.userPassword}
                onChange={(e) => handleFormChange(e)}
                placeholder={translate('please_input_your_x', { x: translate('password') })}
              />
            </InputContainer>
          </InputBorder>
          {formError?.userPassword && <ErrorText>{formError?.userPassword}</ErrorText>}
        </Box>
      ) : (
        ''
      )}

      <Box sx={{ mb: '10px' }}>
        <InputBorder>
          <InputContainer direction="row">
            <StyledIconify icon={'solar:share-circle-outline'} />
            <StyledInput
              name="userReferralCode"
              value={registerForm?.userReferralCode}
              onChange={(e) => handleFormChange(e)}
              placeholder={translate('affiliate_referral')}
            />
          </InputContainer>
        </InputBorder>
      </Box>

      <RegisterOTPForm
        phoneNumber={registerForm?.userPhoneNumber}
        setVerification={setVerification}
        verification={verification}
      />

      {/* <Box sx={{ mb: '10px' }}>
        <Stack direction={'row'} spacing={1}>
          <InputContainer direction="row" alignItems="center" sx={{ flex: 1 }}>
            <StyledIconify icon={'circum:mobile-2'} width={24} height={24} sx={{ color: 'white' }} />
            <StyledInput
              name="otpCode"
              placeholder={'Verification Code'}
              value={otpCode}
              onChange={(e) => setOtpCode((prevState) => e.target.value)}
            />
          </InputContainer>
          <ButtonBase onClick={() => sendOtp()}>GET CODE</ButtonBase>
        </Stack>
      </Box> */}

      {/* <Box sx={{ mb: '10px' }}>
        <InputContainer direction="row">
          <StyledIconify icon={'iconamoon:shield-yes-thin'} />
          <StyledInput
            name="captcha"
            placeholder={translate('captcha')}
            value={registerForm?.captcha}
            onChange={(e) => handleFormChange(e)}
          />
          <Box sx={{ mb: '-5px' }}>
            <LoadCanvasTemplateNoReload />
          </Box>
        </InputContainer>
        {formError?.userCaptcha && <ErrorText>{formError?.userCaptcha}</ErrorText>}
      </Box> */}

      <Stack direction={'row'} alignItems={'center'} mt={2} mb={2}>
        <FormControlLabel
          control={
            <Checkbox
              sx={{ width: 'fit-content', color: palette.visitor.dialog.otherText }}
              value={tncConfirmed}
              checked={tncConfirmed}
              onChange={(e) => setTncConfirmed(e.target.checked)}
            />
          }
          label={
            <FooterText>
              {translate('confirm_18')}{' '}
              <LinkText onClick={() => {
                dispatch(openLoginSignup({ open: false, isLogin: false }))
                navigate('tnc', {
                  state: {
                    pageKey: 'tncDocumentBerry96',
                  },
                })
              }}>{translate('terms_and_condition')}</LinkText>
            </FooterText>
          }
        />
      </Stack>
      <RegisterButton variant="contained" onClick={() => verifyOtp()} loading={isLoading} disabled={isValidSubmit}>
        {translate('sign_up')}
      </RegisterButton>

      <FooterText
        onClick={() => setActiveTab('1')}
        sx={{
          mt: 2,
        }}
      >
        {translate('already_have_account')} <LinkText>{translate('login')}</LinkText>
      </FooterText>
      {statusMessage?.open && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={statusMessage.open}
          autoHideDuration={3000}
          sx={{ width: 'fit-content' }}
          onClose={() => setStatusMessage((prevState) => ({ ...prevState, open: false }))}
        >
          <Alert
            onClose={() => setStatusMessage((prevState) => ({ ...prevState, open: false }))}
            severity={statusMessage.type}
            sx={{ width: '100%' }}
          >
            {statusMessage.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

Register.propTypes = {
  setActiveTab: PropTypes.func,
};

export default Register;
