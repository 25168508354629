// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const cn = {
  game: '游戏',
  games: '游戏',
  sport: '运动',
  center: '中心',
  mines: '地雷',
  crash: '崩溃',
  lucky_colour: '幸运颜色',
  dice: '骰子',
  limbo: '临界',
  lucky_gift: '幸运礼物',
  all_games: '所有游戏',
  game_history: '游戏历史',
  coupon_code: '优惠券代码',
  telegram: '电报',
  customer_service: '客户服务',
  player: '玩家',
  winner: '赢家',
  all_bets: '所有投注',
  my_bets: '我的投注',
  name: '姓名',
  time: '时间',
  bet_amount: '投注金额',
  prediction: '预测',
  payout: '支付',
  login: '登录',
  register: '注册',
  username_placeholder: '用户名',
  please_input_your_x: '请输入您的{{x}}',
  affiliate_referral: `联盟/推荐ID（可选）`,
  captcha: '验证码',
  forgot_password: '忘记密码',
  minor_note: '访问本网站，请确保您已满18岁并同意',
  terms_of_service: '服务条款',
  by_signing_in: '通过登录或注册，您同意我们的',
  dont_have_account: '没有账户？',
  sign_up: '注册',
  username: '用户名',
  email: '电子邮件',
  full_name: '全名',
  confirm_18: '点击注册按钮，即表示我确认自己已年满18岁，并已阅读并接受您的',
  already_have_account: '已有账户？',
  share: '分享',
  deposit: '存款',
  promos: '促销',
  bonus: '奖金',
  app: '应用程序',
  load_more: '加载更多',
  details: '详情',
  helps: '帮助',
  kyc_policy: 'KYC政策',
  privacy_policy: '隐私政策',
  responsible_gaming: '责任博彩',
  terms_and_condition: '条款和条件',
  faq: '常见问题',
  about_us: '关于我们',
  amount: '金额',
  minimum: `最小值`,
  maximum: `最大值`,
  enter_amount: `輸入金額`,
  extra: '额外',
  deposit_bonus: '存款奖金',
  do_not_participate: '不参加促销',
  payment_problem: '支付问题',
  click_for_help: '点击寻求帮助',
  deposit_notes: '存款说明',
  available_balance: '可用余额',
  withdraw: '提款',
  withdrawal_notes: '提款说明',
  withdrawal_note1: '当前提款次数',
  withdrawal_note1_1: 'vip',
  withdrawal_note2: '提款费用：',
  min: '最低：',
  max: '最高：',
  opening_times: '开放时间：',
  withdrawal_note3: `1.最快提款时间为10分钟内`,
  withdrawal_note4: `2.请确保您的提款信息准确无误，如果提款信息有误，可能会导致资金损失。`,
  withdrawal_note5: `3.如有任何疑问，请随时联系我们。`,
  notifications: `通知`,
  invalid_x: `无效的{{x}}`,
  captcha_not_match: `验证码不匹配`,
  invalid_x_must_be_atleast_y_characters: `无效的{{x}}，必须至少{{y}}个字符`,
  x_is_required: `{{x}}是必需的`,
  phone_number: `电话号码`,
  phone_num_x_max: `电话号码最多{{x}}位数字`,
  email_sent: `邮件已发送，请检查您的电子邮件。`,
  enter_valid_email: `请输入有效的电子邮件地址。`,
  confirm: `确认`,
  password_atleast_6_characters: `密码必须至少6个字符。`,
  password_reset_success: `密码重置成功！`,
  new_password: `新密码`,
  bank: `银行`,
  bank_transfer: `银行转账`,
  bank_name: `银行名称`,
  account_name: `账户名称`,
  account_number: `账号`,
  pix_number: `PIX号码`,
  drop_or_select: `拖放或选择文件`,
  drop_files_here: `将文件拖放到此处`,
  transfer_to: `转账至`,
  upload_receipt: `上传收据`,
  select_bonus: `选择奖金`,
  no_promotion: `没有促销`,
  please_upload_receipt: `请上传收据`,
  bank_account: `银行账户`,
  add_account: `添加账户`,
  account_holder_name: `账户持有人姓名`,
  name_of_the_account_holder: `账户持有人的姓名`,
  fill_in_bank_account: `填写银行账户信息`,
  withdraw_information: `提款信息`,
  note: `备注`,
  amount_withdrawn: `提款金额`,
  amount_no_exceed_balance: `提款金额不得超过可用余额`,
  minimum_withdraw_amount_is_x: `最低提款金额为{{x}}`,
  transaction_history: `交易历史`,
  date_from: `开始日期`,
  date_to: `结束日期`,
  account_name_must_be_4: `账户名称长度必须大于4`,
  account_number_must_be_x: `账户号码长度必须为10到14位`,
  minimum_length_is_x: `最小长度为{{x}}`,
  maximum_length_is_x: `最大长度为{{x}}`,
  payment_method: `付款方式`,
  mode: `模式`,
  no_bank_account: `您没有可提款的账户，请立即添加`,
  please_select_bank: `请选择一个银行账户`,

  bet_history: `投注历史`,
  game_provider: `游戏提供商`,
  bet_count: `投注次数`,
  win_loss: `赢/输`,
  rebate: `返利`,
  turnover: `营业额`,
  game_category: `游戏类别`,

  you_have_x_unread_messages: `您有{{x}}条未读消息`,
  deposit_amount_between_x_and_y: `存款金额必须在{{x}}和{{y}}之间`,
  minimum_amount_is_x_y: `最低金额为{{x}}{{y}}`,

  all: `所有`,
  title: `标题`,
  date: `日期`,
  type: `类型`,
  status: `状态`,
  remark: `备注`,
  category: `类别`,
  please_select_x: `请选择{{x}}`,
  save: `保存`,

  change_password: `更改密码`,
  current_password: `当前密码`,
  confirm_password: `确认新密码`,
  submit: `提交`,
  password_changed: `密码已更改`,
  x_must_be_y_characters: `{{x}}必须至少{{y}}个字符`,
  reset_password: `重置密码`,
  casino: `赌场`,
  slots: `老虎机`,
  live_casino: `真人赌场`,
  fishing: `钓鱼`,
  sports: `体育`,
  cards: `牌类`,
  clear: `清除`,
  search: `搜索`,
  new: `新的`,
  logout: `登出`,
  choose_date: `选择日期`,
  select_both_date: `请选择开始和结束日期`,
  from: `从`,
  to: `到`,
  apply: `应用`,
  promotions: `促销`,
  learn_more: `了解更多`,
  password_doesnt_match: `密码不匹配`,
  cancel: `取消`,
  confirm_to_pay: `确认支付`,
  deposit_amount: `存款金额`,
  loading_please_wait: `加载中，请稍候`,
  account: `账户`,
  profile: `个人资料`,
  bank_list: `银行列表`,
  in_maintenance: `维护中`,
  birthday: `生日`,
  birthday_bonus: `输入你的生日领取奖金！🎉🎁`,
  referral_code: `推荐码`,
  referral: `推荐`,
  my_referral: `我的推荐`,
  my_claim: `我的索赔`,
  claim_profit: `领取利润`,
  available_profit: `可用利润`,
  claimed_profit: `已领取利润`,
  all_time_profit: `总利润`,
  referral_note_on_x: `每次您的朋友在{{x}}上玩游戏时都会赚取利润`,
  tier: `级别`,
  commission: `佣金`,
  referred_member: `被推荐成员`,
  profit_earned: `赚取的利润`,
  account_information: `账户信息`,
  invite_friends: `邀请朋友`,
  how_it_work: `怎么运作？`,
  invite_note: `当您的朋友使用您的推荐码注册时，获得独家推荐奖金！`,
  share_via: `通过分享`,
  referral_link: `推荐链接`,
  total_profit: `总利润`,
  copied: `已复制`,
  nothing_to_claim: `没有可领取的`,
  claim_history: `索赔历史`,
  downline: `我的下线`,
  last_x_digit_bank_ref_number: `银行参考号码的最后{{x}}位数字`,
  bank_ref_number_validation_x: `银行参考号码是必需的，长度必须为{{x}}个字符`,
  sort: `排序`,
  login_directly: `直接登录`,
  our_game_provides: `我们的游戏提供商`,
  refer_and_reward: `推荐与奖励`,
  linked_successfully_x: `已成功连接{{x}}！请首次注册您的账户`,
  more_x_of_y: `更多{{x}}/{{y}}`,
  default: `默认`,
  promotion: `促销`,
  daily_mission: `每日任务`,
  agent: `代理`,
  contact_us: `联系我们`,
  download: `下载`,
  cash_claim: `现金领取`,
  demo: `演示`,
  favorites: `收藏`,
  popular: `热门`,
  claim_bonus: `领取奖金`,
  insufficient_balance: `余额不足`,
  currently_displaying: `当前显示{{z}}个游戏中的{{x}}个{{y}}游戏`,
  all_x: `所有{{x}}`,
  no_results: `未找到结果`,
  search_games: `搜索游戏`,
  please_scan_using_your: `请使用您的`,
  vip_level: `VIP等级`,
  bonus_direct: `直接奖金`,
  rebate_level: `返利等级`,
  vip_header: `在 Berry96 上，所有在老虎机、桌牌游戏、捕鱼、视频、体育和彩票游戏上投注的赌注将永久累计。有效投注 = 永久收入和财富！`,
  vip_example: `
  例子 1：累计存款达到 100 万的会员，达到 VIP 等级 2，获得 210 雷亚尔的奖金和 3.50% 的返利。当累计存款达到 600 万时，达到 VIP 等级 3，获得 800 雷亚尔的奖金和 4% 的返利`,
  activity_rules: `活动规则：`,
  activity_rules_1: `1. 所有奖金必须进行 x3 的投注才能申请提款`,
  activity_rules_2: `2. 请在截止日期内领取返利奖金，如果迟了，将被视为放弃；`,
  activity_rules_3: `3. 会员的活动参与情况由系统自动统计，如果有任何异议，以 Berry96 的调查结果为准；`,
  activity_rules_4: `4. 如果您忘记了会员账户/密码，请联系“24 小时在线客户服务”帮助您找回账户信息；`,
  activity_rules_5: `5. Berry96 保留随时更改、停止或取消本次促销活动的权利。`,
  activity_rules_6: `6. 不要保存免费游戏，购买免费游戏`,
  activity_rules_7: `7. 不能与任何奖金结合使用`,
  activity_rules_8: `8. 每次存款的最高提款金额为 R$100.000.00`,
  event_instructions: '活动说明',
  agent_p1: '在应用程序和社交媒体上分享您的唯一链接',
  agent_p2:
    'Facebook、Instagram、Tiktok、Telegram、WhatsApp、YouTube 等，赶快邀请大家，通过您的专属链接注册的会员将成为您的直接代理。但请注意，您邀请的新会员必须是真实和有效的。',
  agent_step1:
    '邀请朋友首次存款成功，您可以赢得 R$5 的奖金。例如，如果您邀请 10 人首次存款，您可以赚取 R$50。您邀请的人越多，获得的奖金就越多。您可以在促销页面查看您的历史和注册。',
  agent_step2:
    '如果您的客人投注 R$1 或以上，您就可以享受高佣金！您可以赚取最高 5% 的终身佣金，并享受终身邀请！系统将在次日早上 7 点结算前一天的佣金。',
  agent_step2_1: '获取方法：登录 - 邀请促销 - 收集佣金',
  agent_table1: '老虎机、捕鱼和区块链的投注佣金率如下：',
  agent_table1_1: '客户充值 20 投注 200',
  person: '人',
  agent_table2_title1: '会员投注',
  agent_table2_title2: '仅限老虎机游戏',
  agent_table2_example:
    '例子：您有 100 个会员，每天的总真实投注金额估计为 100 万雷亚尔。当天您收到的佣金是：1,000,000 x 1.20% 12,000 雷亚尔。每月赚取 100,000 雷亚尔的收入是很容易实现的。',
  reminder: '提醒',
  agent_reminder:
    '一个具有相同 IP、相同设备、姓名、银行卡等信息的会员账户只能参与一次，任何违反规则的人将无法获得此奖金。或者该组将被没收，账户将被永久冻结。',
  agent_step3_1: '会员的活动参与情况由系统自动统计，如果有任何异议，以 Berry96 的分析结果为准',
  agent_step3_2: '如果您忘记了会员账户/密码，请联系我们的 24 小时客户服务团队，帮助您找回账户信息',
  agent_step3_3: 'Berry96 保留随时更改、中断或取消本次促销活动的权利',
  agent_step3_4: '代理人能够以现金或钱包中的积分形式获得佣金奖金',
  agent_step3_5: '不要保存免费游戏，购买免费游戏',
  agent_step3_6: '不能与任何奖金和存款结合使用',
  agent_step3_7: '仅限老虎机游戏',
  promo_concluded: '促销已结束！请明天再来！',
  can_only_claim: '只能在巴西时间 {{x}} 到 {{y}} 之间领取奖金',
  please_login: '请先登录以领取奖金！',
  back_to_home: '返回首页',
  bonus_claimed: '奖金已领取！',
  wallet: '钱包',
  bank_bsb: `银行 BSB`,
  pay_id: `PayID`,
  from_selected_promotion: '从选定的促销中',
  helpful_links: '帮助链接',
  platform: '平台',
  community: '社区',
  support: '支持',
  partnership_program: '合作计划',
  help_center: '帮助中心',
  sports_policy: '体育政策',
  aml_policy: '反洗钱政策',
  level: '级别',
  back: '回去',
  network_error: '网络错误',
  we_have_sent_otp: '我们已将 OTP 发送到您的电话号码',
  your_otp_expire: '您的 OTP 将于',
  didnt_receive: '没有收到消息？',
  send_again: '重新发送',
  resend_again: '再次重新发送',
  invalid_verification: '无效验证码！',
  otp_expired: 'OTP 已过期，请重新输入您的电话号码并重试！',
  otp_expired_redirect: '一次性密码已过期！重定向到登录',
  captcha_check_failed: '验证码检查失败！',
  verify_phonenumber: '验证电话号码',
  send_otp: '发送一次性密码',
  success_verification: '您的电话号码已被验证！',
  press_get_code: '按获取代码按钮通过短信接收验证码',
  otp_sent: '验证码已发送！输入下面的验证码继续',
  get_code: '获取代码',
  verify: '核实',
  verification_code: '验证码',
  otp_not_found: '未找到 OTP 功能！',
  terms_n_conditions: `TERMS AND CONDITION`,
  the_activity_x_in: `活动 {{x}} 在`,
  starts: `开始`,
  ends: `结束`,
  list_of_winners: `获奖者名单`,
  days: `天`,
  hours: `小时`,
  minutes: `分钟`,
  seconds: `秒数`,
  total_amount_received: `收到的总金额`,
  claim: `宣称`,

  docs: {
    hi: `嗨`,
    description: `需要帮助吗？\n 请查看我们的文档。`,
    documentation: `文档`,
  },
  user: `用户`,
  list: `列表`,
  edit: `编辑`,
  shop: `商店`,
  blog: `博客`,
  post: `帖子`,
  mail: `邮件`,
  chat: `聊天`,
  posts: `帖子`,
  create: `创建`,
  kanban: `看板`,
  general: `通用`,
  banking: `银行`,
  booking: `预订`,
  product: `产品`,
  invoice: `发票`,
  checkout: `结账`,
  calendar: `日历`,
  analytics: `分析`,
  ecommerce: `电子商务`,
  management: `管理`,
  menu_level_1: `菜单级别 1`,
  menu_level_2: `菜单级别 2`,
  menu_level_3: `菜单级别 3`,
  item_disabled: `项目已禁用`,
  item_label: `项目标签`,
  item_caption: `项目说明`,
  description: `描述`,
  other_cases: `其他情况`,
  item_by_roles: `按角色分配的项目`,
  only_admin_can_see_this_item: `仅管理员可见此项目`,
  number_exists: '该电话号码已被注册',
  number_not_exists: '该电话号码尚未注册',
  payment_draft: '如果您确认已付款，请稍等片刻，因为付款可能仍在处理中',
  card_game: '纸牌游戏',
  card: '如果您确认已付款',
  live_chat: '在线聊天',
  fullname_note: '确保您的全名与您的银行账户匹配，以避免提款问题。',
  please_download_x_to_play: '请下载{{x}}以进行游戏。',
  password: '密码',
  play_now: '立即游戏',
  vip_benefits: 'VIP福利',
  no_limits: '无限制',
  no_limits_description:
    '我们为所有VIP俱乐部的玩家提供个性化的VIP服务和独特的游戏条件。现在你可以毫无顾虑地玩了！这非常简单——为了刺激而玩，提升你的游戏技能，享受你的时光，并且在获胜机会方面没有限制！',
  financial_privileges: '财务特权',
  financial_privileges_description:
    'VIP俱乐部欢迎所有希望获得高质量VIP服务的玩家。在我们的网站上为您提供独家特权、特别促销和福利！VIP玩家的身份解锁了更高的提款限额，而且时间非常短！尽情享受吧！',
  personal_manager: '私人经理',
  personal_manager_description:
    '当您成为VIP玩家时，您希望获得哪些优势？与您的私人经理分享您的想法，并告诉我们您希望在我们的网站上看到的更多内容！我们始终乐于接受伟大的建议！',
  vip_promotions: 'VIP促销',
  vip_promotions_description:
    '您是否希望获得更多的精彩促销，VIP玩家可以享受所有这些以及更多的奖励作为忠诚的回报！成为其中一员吧！',
  become_vip: '成为VIP',
  play_the_way_you_want: '按照您想要的方式玩',
  join_vip_program: '立即加入VIP计划！',
  join_now: '立即加入',
  vip_club: 'VIP俱乐部',
  withdraw_limit: '提现限额',
  cashback: '现金返还',
  personal_account_manager: '个人账户经理',
  dont_show_again: '不再显示',
  next: '下一步',
  close: '关闭',
};

export default cn;
