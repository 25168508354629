// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ms = {
  no: `Tidak`,
  select_country_code: `Pilih Kod Negara`,
  balance: `Baki`,
  requirement: `Keperluan`,
  deposit_from_bank: `Deposit Dari Bank`,
  game: `Permainan`,
  games: `Permainan`,
  sport: `Sukan`,
  center: `Pusat`,
  mines: `MINES`,
  crash: `CRASH`,
  lucky_colour: `LUCKY COLOUR`,
  dice: `DICE`,
  limbo: `LIMBO`,
  lucky_gift: `LUCKY GIFT`,
  all_games: `Semua permainan`,
  game_history: `Sejarah Permainan`,
  coupon_code: `Kod Kupon`,
  telegram: `Telegram`,
  customer_service: `Khidmat Pelanggan`,
  player: `Pemain`,
  winner: `Pemenang`,
  all_bets: `Semua Pertaruhan`,
  my_bets: `Pertaruhan Saya`,
  name: `Nama`,
  time: `Masa`,
  bet_amount: `Jumlah Pertaruhan`,
  prediction: `Ramalan`,
  payout: `Pembayaran`,
  login: `Log masuk`,
  register: `Daftar`,
  username_placeholder: `Nama pengguna`,
  please_input_your_x: `Sila Masukkan {{x}} Anda`,
  affiliate_referral: `ID Rujukan Afiliasi (PILIHAN)`,
  captcha: `CAPTCHA`,
  forgot_password: `Lupa kata laluan`,
  minor_note: `Untuk melawat laman web ini, pastikan anda berumur lebih dari 18 tahun dan bersetuju`,
  terms_of_service: `Syarat Perkhidmatan`,
  by_signing_in: 'Dengan Log Masuk atau Mendaftar, anda bersetuju dengan kami',
  dont_have_account: `Tiada akaun?`,
  sign_up: `Daftar`,
  username: `Nama pengguna`,
  email: `E-mel`,
  full_name: `Nama penuh`,
  confirm_18: `Dengan mengklik butang Daftar, saya dengan ini mengakui bahawa saya berumur lebih dari 18 tahun dan telah membaca serta menerima`,
  already_have_account: `Sudah mempunyai akaun?`,
  share: `Kongsi`,
  deposit: `Deposit`,
  promos: `Promosi`,
  bonus: `Bonus`,
  app: `App`,
  load_more: `Tambah lagi`,
  details: `Butiran`,
  helps: `MEMBANTU`,
  kyc_policy: `Dasar KYC`,
  privacy_policy: `Dasar Privasi`,
  responsible_gaming: `Permainan yang bertanggungjawab`,
  terms_and_condition: `Terma dan syarat`,
  faq: `FAQ`,
  about_us: `TENTANG KITA`,
  amount: `Jumlah`,
  minimum: `Minima`,
  maximum: `Maks`,
  enter_amount: `Masukkan Jumlah`,
  extra: `Tambahan`,
  deposit_bonus: `Bonus Deposit`,
  do_not_participate: `Jangan sertai promosi`,
  payment_problem: `Masalah Pembayaran`,
  click_for_help: `Klik untuk mendapatkan bantuan`,
  deposit_notes: `Nota Deposit`,
  available_balance: `Baki yang ada`,
  withdraw: `Tarik Diri`,
  withdrawal_notes: `Nota Pengeluaran`,
  withdrawal_note1: `Bilangan pengeluaran semasa`,
  withdrawal_note1_1: `vip`,
  withdrawal_note2: `Yuran pengeluaran:`,
  min: `Min:`,
  max: `Maks:`,
  opening_times: `Masa pembukaan:`,
  withdrawal_note3: `1. Masa pengeluaran terpantas dalam masa 10 minit`,
  withdrawal_note4: `2.Pastikan maklumat pengeluaran anda adalah tepat, jika maklumat pengeluaran salah, ia boleh menyebabkan dana anda hilang.`,
  withdrawal_note5: `3. Sila hubungi kami jika anda mempunyai sebarang pertanyaan.`,
  notifications: `Pemberitahuan`,
  invalid_x: `{{x}} tidak sah`,
  captcha_not_match: `Captcha Tidak Padan`,
  invalid_x_must_be_atleast_y_characters: `{{x}} tidak sah, mestilah sekurang-kurangnya {{y}} aksara`,
  x_is_required: `{{x}} diperlukan`,
  phone_number: 'Nombor telefon',
  phone_num_x_max: `Nombor Telefon maksimum {{x}} digit`,
  email_sent: `E-mel dihantar, sila semak e-mel anda.`,
  enter_valid_email: `Sila masukkan e-mel yang sah.`,
  confirm: `Sahkan`,
  password_atleast_6_characters: `Kata laluan mestilah sekurang-kurangnya 6 aksara.`,
  password_reset_success: `Kejayaan tetapan semula kata laluan!.`,
  new_password: `Kata laluan baharu`,
  bank: 'Bank',
  bank_transfer: `Pemindahan bank`,
  bank_name: `Nama bank`,
  account_name: `Nama akaun`,
  account_number: `Nombor akaun`,
  pix_number: `PIX Number`,
  drop_or_select: `Lepaskan atau Pilih fail`,
  drop_files_here: `Lepaskan fail di sini`,
  transfer_to: `Pemindahan Kepada`,
  upload_receipt: `Muat Naik Resit`,
  select_bonus: `Pilih Bonus`,
  no_promotion: `Tiada Promosi`,
  please_upload_receipt: `Sila muat naik resit`,
  bank_account: `Akaun bank`,
  add_account: `Tambah akaun`,
  account_holder_name: `Nama Pemegang Akaun`,
  name_of_the_account_holder: `Nama Pemegang Akaun`,
  fill_in_bank_account: `Isikan Akaun Bank`,
  withdraw_information: `Tarik balik Maklumat`,
  note: `Catatan`,
  amount_withdrawn: `Jumlah yang dikeluarkan`,
  amount_no_exceed_balance: `Jumlah Pengeluaran tidak boleh melebihi baki yang ada`,
  minimum_withdraw_amount_is_x: `Jumlah Pengeluaran Minimum ialah {{x}}`,
  transaction_history: `Sejarah Transaksi`,
  date_from: `Tarikh dari`,
  date_to: `Tarikh Kepada`,
  account_name_must_be_4: `Nama Akaun mestilah lebih besar daripada 4 panjang`,
  account_number_must_be_x: `Nombor akaun mestilah 10-14 panjang`,
  minimum_length_is_x: `Minimum length is {{x}}`,
  maximum_length_is_x: `Maximum length is {{x}}`,
  payment_method: `Kaedah Pembayaran`,
  mode: `Mode`,
  no_bank_account: `Anda tidak mempunyai sebarang akaun untuk dikeluarkan, Tambah sekarang`,
  please_select_bank: `Sila pilih Akaun Bank`,

  bet_history: `Sejarah Pertaruhan`,
  game_provider: `Penyedia Permainan`,
  bet_count: `Kiraan Pertaruhan`,
  win_loss: `Menang/Kalah`,
  rebate: `Rebat`,
  turnover: `Perolehan`,
  game_category: `Kategori Permainan`,

  you_have_x_unread_messages: `Anda mempunyai {{x}} mesej yang belum dibaca`,
  deposit_amount_between_x_and_y: `Amaun deposit mestilah antara {{x}} dan {{y}}`,
  minimum_amount_is_x_y: `Jumlah minimum ialah {{x}}{{y}}`,

  // all
  all: `Semua`,
  title: `Tajuk`,
  date: `Tarikh`,
  type: `Taip`,
  status: `Status`,
  remark: `Teguran`,
  category: `kategori`,
  please_select_x: `Sila pilih {{x}}`,
  save: `Jimat`,

  change_password: `Tukar kata laluan`,
  current_password: `Kata Laluan Semasa`,
  confirm_password: `Sahkan Kata Laluan Baharu`,
  submit: `Hantar`,
  password_changed: `Kata Laluan Ditukar`,
  x_must_be_y_characters: `{{x}} mestilah sekurang-kurangnya {{y}} aksara`,
  reset_password: `Menetapkan semula kata laluan`,
  casino: `Kasino`,
  slots: `Slot`,
  live_casino: `Kasino Langsung`,
  fishing: `Memancing`,
  sports: `sukan`,
  cards: `Kad`,
  clear: `Jelas`,
  search: `Cari`,
  new: `Baru`,
  logout: `Log keluar`,
  choose_date: `Pilih Tarikh`,
  select_both_date: `Pilih kedua-dua Tarikh Dari dan Kepada`,
  from: `daripada`,
  to: `Kepada`,
  apply: `Mohon`,
  promotions: `Promosi`,
  learn_more: `KETAHUI LEBIH LANJUT`,
  password_doesnt_match: `Kata laluan tidak sepadan`,
  cancel: `Batal`,
  confirm_to_pay: `Sahkan Untuk Bayar`,
  deposit_amount: `Jumlah Deposit`,
  loading_please_wait: `Muat turun sila tunggu`,
  account: `Akaun`,
  profile: `Profil`,
  bank_list: `Senarai Bank`,
  in_maintenance: `Dalam Penyelenggaraan`,
  birthday: `Hari Jadi`,
  birthday_bonus: `Masukkan hari lahir anda untuk bonus! 🎉🎁`,
  referral_code: `Kod rujukan`,
  referral: `Rujukan`,
  my_referral: `Rujukan Saya`,
  my_claim: `Tuntutan Saya`,
  claim_profit: `TUNTUTAN UNTUNG`,
  available_profit: `Keuntungan yang Tersedia`,
  claimed_profit: `Keuntungan yang Dituntut`,
  all_time_profit: `Keuntungan Sepanjang Masa`,
  referral_note_on_x: `Peroleh keuntungan setiap kali rakan anda bermain di {{x}}`,
  tier: `Peringkat`,
  commission: `Suruhanjaya`,
  referred_member: `Ahli yang dirujuk`,
  profit_earned: `Keuntungan Diperolehi`,
  account_information: `Maklumat Akaun`,
  invite_friends: `Menjemput kawan-kawan`,
  how_it_work: `Bagaimana ia berfungsi?`,
  invite_note: `Dapatkan bonus rujukan eksklusif apabila rakan anda mendaftar menggunakan kod rujukan anda!`,
  share_via: `Kongsi melalui`,
  referral_link: `Pautan Rujukan`,
  total_profit: `Jumlah Keuntungan`,
  copied: `Disalin`,
  nothing_to_claim: `Tiada apa-apa untuk dituntut`,
  claim_history: `Sejarah Tuntutan`,
  downline: `Downline Saya`,
  last_x_digit_bank_ref_number: `{{x}} digit terakhir nombor Ruj Bank`,
  bank_ref_number_validation_x: `Nombor Rujukan Bank diperlukan dan mestilah {{x}} panjang aksara`,
  sort: 'Susun',
  login_directly: 'Log masuk terus dengan',
  our_game_provides: 'PENYEDIA PERMAINAN KAMI',
  we_accept_below_bank: `Kami Terima Bank-Bank Berikut`,
  we_accept_payment_methods: `Kami Terima Kaedah Pembayaran`,
  refer_and_reward: 'Rujuk & Ganjaran',
  linked_successfully_x: 'Berjaya disambungkan dengan {{x}}! Sila daftar akaun anda untuk kali pertama',
  more_x_of_y: 'Lebih {{x}}/{{y}}',
  default: 'Default',
  promotion: 'Promosi',
  daily_mission: 'Misi Harian',
  agent: 'Ejen',
  contact_us: 'Hubungi Kami',
  download: 'Muat Turun',
  cash_claim: 'Tuntutan Tunai',
  demo: 'Demo',
  favorites: 'Kegemaran',
  popular: 'Popular',
  claim_bonus: 'Tuntut Bonus',
  insufficient_balance: 'Baki Tidak Cukup',
  currently_displaying: 'Sedang memaparkan {{x}} {{y}} permainan daripada {{z}}',
  all_x: 'Semua {{x}}',
  no_results: 'Tiada Keputusan Ditemui',
  search_games: 'Cari Permainan',
  please_scan_using_your: 'Sila imbas menggunakan {{y}}',
  vip_level: 'Tahap VIP',
  bonus_direct: 'BONUS LANGSUNG',
  rebate_level: 'Tahap Rabat',
  vip_header:
    'Semua pertaruhan yang diletakkan pada Mesin Slot, Papan dan Permainan Kad, Memancing, Video, Sukan dan Permainan Loteri di Berry96 akan dikumpulkan secara kekal. Pertaruhan sah = Pendapatan Kekal dan Kekayaan Wang!',
  vip_example:
    'Contoh 1: Ahli yang mencapai 1 Juta dalam deposit yang terkumpul, mencapai Tahap VIP 2, menerima bonus sebanyak R$210, dan Rebate 3.50%, Apabila mereka mencapai 6 Juta dalam deposit yang terkumpul, mereka mencapai Tahap VIP 3 dan menerima bonus sebanyak R$800, dengan Rebate 4%.',
  activity_rules: 'Peraturan Aktiviti:',
  activity_rules_1: '1. Adalah wajib bagi semua bonus mempunyai x3 Pusingan untuk meminta Pengeluaran.',
  activity_rules_2:
    '2. Sila tuntut Bonus Rebate dalam tempoh masa yang ditetapkan, jika anda lewat, ia akan dianggap sebagai penampan;',
  activity_rules_3:
    '3. Penyertaan ahli dalam aktiviti akan dikira secara automatik oleh sistem, jika ada sebarang bantahan, hasil siasatan Berry96 akan diutamakan;',
  activity_rules_4:
    "4. Jika anda lupa akaun Ahli/Kata Laluan anda, sila hubungi 'Perkhidmatan Pelanggan 24 Jam Online' untuk membantu anda mendapatkan semula maklumat akaun anda;",
  activity_rules_5: '5. Berry96 berhak untuk mengubah, menghentikan atau membatalkan promosi ini pada bila-bila masa.',
  activity_rules_6: '6. Jangan menyimpan permainan percuma, Beli permainan percuma',
  activity_rules_7: '7. Tidak boleh digabungkan dengan mana-mana bonus',
  activity_rules_8: '8. Pengeluaran Maksimum setiap Deposit adalah R$100,000.00',
  event_instructions: 'Arahan Acara',
  agent_p1: 'Kongsi pautan unik anda di seluruh aplikasi dan media sosial',
  agent_p2:
    'Facebook, Instagram, Tiktok, Telegram, WhatsApp, YouTube, dan lain-lain, cepatlah dan jemput semua orang, ahli yang mendaftar melalui pautan eksklusif anda akan menjadi ejen langsung anda. Tetapi perhatikan kerana ahli baru yang anda jemput mesti adalah tulen dan sah.',
  agent_step1:
    'Dapatkan jemputan rakan apabila deposit pertama berjaya, anda boleh memenangi bonus sebanyak R$5. sebagai contoh, jika anda menjemput 10 orang untuk deposit pertama, anda boleh mendapatkan R$50. Semakin ramai orang yang anda jemput, semakin banyak bonus yang akan anda terima. Anda boleh melihat sejarah anda dan mendaftar di laman promosi untuk bonus dan komisen tinggi.',
  agent_step2:
    'Jika tetamu anda bertaruh R$1 atau lebih, anda sudah boleh menikmati komisen tinggi! Anda boleh mendapatkan sehingga 5% komisen seumur hidup, dan jemputan seumur hidup! Sistem akan menyelesaikan komisen hari sebelumnya pada jam 7 pagi pada hari berikutnya.',
  agent_step2_1: 'Cara untuk mendapatkan: Log masuk - Promosi jemputan - Kumpul komisen',
  agent_table1: 'Kadar komisen pertaruhan untuk slot, memancing dan blockchain adalah seperti berikut:',
  agent_table1_1: 'Pelanggan topup 20 bertaruh 200',
  person: 'orang',
  agent_table2_title1: 'Pertaruhan Ahli',
  agent_table2_title2: 'Hanya Permainan Slot',
  agent_table2_example:
    'Contoh: Anda mempunyai 100 ahli, dan nilai sebenar pertaruhan keseluruhan dianggarkan pada 1 juta reais sehari. Komisen yang anda terima pada hari itu adalah: 1,000,000 x 1.20% = 12,000 reais. Pendapatan bulanan sebanyak 100,000 adalah sangat mudah untuk dicapai.',
  reminder: 'Peringatan',
  agent_reminder:
    'Akaun ahli dengan alamat IP yang sama, peranti yang sama, nama, kad bank, dan maklumat lain hanya boleh menyertai sekali, dan sesiapa yang melanggar peraturan ini tidak akan mendapat manfaat daripada bonus ini. atau kumpulan akan dirampas dan akaun akan dibekukan secara kekal',
  agent_step3_1:
    'Penyertaan ahli dalam aktiviti diira secara automatik oleh sistem, jika ada sebarang bantahan, hasil analisis yang dilakukan oleh Berry96 akan diutamakan',
  agent_step3_2:
    'Jika anda lupa akaun ahli/kata laluan anda, sila hubungi pasukan perkhidmatan pelanggan kami yang beroperasi 24 jam untuk membantu anda mendapatkan semula maklumat akaun anda',
  agent_step3_3: 'Berry96 berhak untuk mengubah, menghentikan atau membatalkan promosi pada bila-bila masa',
  agent_step3_4: 'Ejen yang menerima bonus komisen adalah dalam bentuk tunai atau kredit dalam dompet',
  agent_step3_5: 'Jangan menyimpan permainan percuma, Beli permainan percuma',
  agent_step3_6: 'Tidak boleh digabungkan dengan sebarang bonus dan deposit',
  agent_step3_7: 'Hanya tersedia untuk permainan Slot',
  promo_concluded: 'Promosi telah tamat! Semak semula esok!',
  can_only_claim: 'Hanya boleh tuntut bonus antara {{x}} hingga {{y}} Waktu Brazil',
  please_login: 'Sila Log Masuk terlebih dahulu untuk menuntut bonus!',
  back_to_home: 'Kembali ke Laman Utama',
  bonus_claimed: 'Bonus Telah Dituntut!',
  wallet: 'Dompet',
  bank_bsb: 'BSB Bank',
  pay_id: 'PayID',
  from_selected_promotion: 'daripada promosi terpilih',
  helpful_links: 'Pautan Berguna',
  platform: 'Platform',
  community: 'Komuniti',
  support: 'Sokongan',
  partnership_program: 'Program Rakan Kongsi',
  help_center: 'Pusat Bantuan',
  sports_policy: 'Dasar Sukan',
  aml_policy: 'Dasar AML',
  level: 'Level',
  back: 'Belakang',
  network_error: 'Ralat Rangkaian',
  we_have_sent_otp: 'Kami telah menghantar OTP ke nombor telefon anda',
  your_otp_expire: 'OTP anda akan tamat tempoh dalam',
  didnt_receive: 'Tidak menerima mesej?',
  send_again: 'Hantar semula',
  resend_again: 'Hantar semula sekali lagi',
  invalid_verification: 'Kod pengesahan tidak sah!',
  otp_expired: 'OTP tamat tempoh, sila masukkan semula nombor telefon anda dan cuba lagi!',
  otp_expired_redirect: 'OTP tamat tempoh! mengubah hala ke log masuk',
  captcha_check_failed: 'Semakan Captcha Gagal!',
  verify_phonenumber: 'Sahkan Nombor Telefon',
  send_otp: 'Hantar OTP',
  success_verification: 'Nombor Telefon anda telah disahkan!',
  press_get_code: 'Tekan Butang GET CODE Untuk Menerima Kod Pengesahan Melalui SMS',
  otp_sent: 'Kod Pengesahan Dihantar! Masukkan kod pengesahan di bawah untuk meneruskan',
  get_code: 'Dapatkan kod',
  verify: 'Verify',
  verification_code: 'Kod pengesahan',
  otp_not_found: 'Fungsi OTP Tidak Ditemui!',
  terms_n_conditions: `TERMA DAN SYARAT`,
  the_activity_x_in: `Aktiviti {{x}} Dalam`,
  starts: `Bermula`,
  ends: `Tamat`,
  list_of_winners: `SENARAI PEMENANG`,
  days: `Hari`,
  hours: `Hours`,
  minutes: `Minit`,
  seconds: `Detik`,
  total_amount_received: `Jumlah Jumlah Diterima`,
  claim: `Tuntutan`,
  requirement_to_withdraw: `Keperluan untuk menarik diri:`,
  total_bet_x: `Jumlah Pertaruhan {{x}}`,
  x_of_y: `{{x}} daripada {{y}}`,
  no_balance: `Tiada Baki`,
  fullname_note: `Pastikan nama penuh anda sepadan dengan akaun bank anda untuk mengelakkan masalah pengeluaran.`,
  number_exists: 'Nombor telefon ini sudah didaftarkan!',
  number_not_exists: 'Nombor telefon ini tidak didaftarkan!',
  payment_draft:
    'Jika anda mengesahkan anda telah membayar, sila tunggu sebentar kerana pembayaran mungkin masih diproses',
  live_chat: 'Perbualan Langsung',
  please_download_x_to_play: 'Sila muat turun {{x}} untuk bermain.',
  password: 'Kata Laluan',
  play_now: 'Main Sekarang',
  vip_benefits: 'Faedah VIP',
  no_limits: 'Tiada Had',
  no_limits_description:
    'Kami menawarkan perkhidmatan VIP peribadi dengan syarat permainan unik kepada semua pemain di kelab VIP. Kini anda boleh bermain tanpa sebarang kebimbangan! Ia sangat mudah - bermain untuk keseronokan, memperbaiki kemahiran permainan anda, menikmati masa anda dan tiada had pada peluang kemenangan anda!',
  financial_privileges: 'Keistimewaan Kewangan',
  financial_privileges_description:
    'Kelab VIP mengalu-alukan semua pemain yang ingin mendapatkan perkhidmatan VIP berkualiti tinggi. Keistimewaan eksklusif, promosi dan faedah khas tersedia untuk anda di Laman Web kami! Status pemain VIP membuka had pengeluaran yang lebih tinggi dalam masa yang sangat singkat! Nikmatilah!',
  personal_manager: 'Pengurus Peribadi',
  personal_manager_description:
    'Apakah kelebihan yang anda ingin dapatkan apabila anda menjadi pemain VIP? Kongsikan pendapat anda dengan pengurus peribadi anda dan beritahu kami apa lagi yang anda ingin lihat di Laman Web kami! Kami sentiasa terbuka untuk cadangan yang hebat!',
  vip_promotions: 'Promosi VIP',
  vip_promotions_description:
    'Adakah anda ingin mendapatkan lebih banyak promosi hebat, pemain VIP menikmati semua ini dan banyak lagi sebagai ganjaran untuk kesetiaan! Jadilah salah seorang daripada mereka!',
  become_vip: 'Jadi VIP',
  play_the_way_you_want: 'Main seperti yang anda mahu',
  join_vip_program: 'Sertai Program VIP Sekarang!',
  join_now: 'Sertai Sekarang',
  vip_club: 'Kelab VIP',
  withdraw_limit: 'Had Pengeluaran',
  cashback: 'Pulangan Tunai',
  personal_account_manager: 'Pengurus Akaun Peribadi',
  dont_show_again: 'Jangan tunjuk lagi',
  next: 'Seterusnya',
  close: 'Tutup',
};

export default ms;
