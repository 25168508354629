import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { AppBar, Box, Button, Stack, Toolbar, Typography } from '@mui/material';
// import { loadCaptchaEnginge } from 'react-simple-captcha';
// hooks
import { m } from 'framer-motion';
import { useParams } from 'react-router';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import LoginSignupDialog from './visitor/LoginSignupDialog';
import ForgotPasswordDialog from './forgotpassword/ForgotPasswordDialog';
import ResetPasswordDialog from './forgotpassword/ResetPasswordDialog';
import { useDispatch, useSelector } from '../../../redux/store';
import { openLoginSignup } from '../../../redux/slices/authPages';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';

import Image from '../../../components/Image';
import NotificationPopover from './NotificationPopover';
import AccountPopover from './AccountPopover';
import useLocales from '../../../hooks/useLocales';
import { fNumber } from '../../../utils/formatNumber';
import palette from '../../../theme/palette';
import useRedirect from '../../../hooks/useRedirect';
import useAuth from '../../../hooks/useAuth';
import LanguagePopover from './LanguagePopover';
import CountryPopover from './CountryPopover';
import { GoldBorderButton, GradientBorderWrapper } from '../../../components/settings/GlobalStyled';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: '0px 1px 5px 5px rgb(0, 0, 0, 0.2)',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,

  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  // [theme.breakpoints.up('lg')]: {
  //   height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  //   width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
  //   ...(isCollapse && {
  //     width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
  //   }),
  //   ...(isOffset && {
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //   }),
  //   ...(verticalLayout && {
  //     width: '100%',
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //     backgroundColor: theme.palette.background.default,
  //   }),
  // },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  onToggleCollapse: PropTypes.func,
};

export default function DashboardHeader({
  isOpenSidebar = false,
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
  // onCloseSidebar,
  onToggleCollapse,
}) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const { isAuthenticated } = useAuth();

  const isDesktop = useResponsive('up', 'lg');

  const isSmall = useResponsive('down', 'md');

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const { isOpenLoginSignupDialog } = useSelector((x) => x.authPages);

  const { memberBalance } = useSelector((x) => x.lookup);

  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);

  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);

  const navigate = useRedirect();

  const params = useParams();

  useEffect(() => {
    if (!isAuthenticated && localStorage.getItem(`referralCode-${params?.domain}`)) {
      setTimeout(() => {
        dispatch(openLoginSignup({ open: true, isLogin: false }));
      }, 1000);
    }

    // Check if got resetpassword token
    if (localStorage.getItem(`resetToken-${params?.domain}`)) {
      setOpenResetPasswordDialog(true);
    }
  }, [params]);

  // useEffect(() => {
  //   if (isOpenLoginSignupDialog.open)
  //     setTimeout(() => {
  //       loadCaptchaEnginge(4);
  //     }, 1000);
  // }, [isOpenLoginSignupDialog]);

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
      className="bg-gradient-gray gold-border-top-bot"
    >
      <Toolbar
        sx={{
          minHeight: '100% !important',
          pl: { lg: 1 },
          pr: { lg: 2 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <Stack direction="row" alignItems={'center'} sx={{ mr: 0, height: '100%' }}>
            <Box onClick={onOpenSidebar} sx={{ cursor: 'pointer', height: '35px' }}>
              <Iconify icon={'ion:menu'} color="#FFF" width="35px" height="35px" />
            </Box>
            {/* <Image
              src={require('../../../assets/home/menu.png')}
              alt="Menu"
              sx={{ height: 'auto', width: '40px', transform: 'rotate(180deg)', cursor: 'pointer' }}
              className={`menu-rotate-${isOpenSidebar ? 'close' : 'open'}`}
              onClick={onOpenSidebar}
            /> */}
            <Box onClick={() => navigate('/')}>
              <Image
                src={require('../../../assets/brand-logo.gif')}
                alt=""
                sx={{
                  cursor: 'pointer',
                  width: '20vw',
                  height: '100%',
                  '& img': { maxHeight: '9.5vh', width: 'auto' },
                }}
              />
            </Box>
            {/* <Image
              onClick={() => navigate('/home')}
              className="header-logo"
              src={require('../../../assets/brand-logo.gif')}
              alt="App Logo"
              sx={{ height: { sm: '50px', xs: '10vw' }, width: 'auto', ml: 1, cursor: 'pointer' }}
            /> */}
          </Stack>
        )}

        {isDesktop && (
          <Stack direction="row" alignItems={'center'}>
            <Box
              onClick={() => onToggleCollapse()}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
                p: '5px',
                mr: 2,
                cursor: 'pointer',
              }}
            >
              <Iconify icon={'ion:menu'} color="#FFF" width="25px" height="25px" />
            </Box>
            {/* <Image
              src={require('../../../assets/home/menu.png')}
              alt="Menu"
              sx={{ height: 'auto', width: '40px', transform: 'rotate(180deg)', cursor: 'pointer' }}
              className={`menu-rotate-${isCollapse ? 'close' : 'open'}`}
              onClick={() => onToggleCollapse()}
            /> */}
            <Image
              className="header-logo"
              onClick={() => navigate('/')}
              src={require('../../../assets/brand-logo.gif')}
              alt="App Logo"
              sx={{
                height: '5rem',
                width: '6rem',
                ml: -2,
                cursor: 'pointer',
              }}
            />
          </Stack>
        )}

        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={1}>
          <CountryPopover />
          {!isAuthenticated ? (
            <>
              <Button
                size={isSmall ? 'small' : 'medium'}
                variant="contained"
                onClick={() => dispatch(openLoginSignup({ open: true, isLogin: true }))}
              >
                {translate('login')}
              </Button>
              <GoldBorderButton
                size={isSmall ? 'small' : 'medium'}
                onClick={() => dispatch(openLoginSignup({ open: true, isLogin: false }))}
                wrapperProps={{
                  component: m.button,
                  animate: {
                    boxShadow: [
                      '0px 0px 5px 0px rgba(186, 186, 47,0.75)',
                      '0px 0px 15px 0px rgba(186, 186, 47,0.75)',
                      '0px 0px 5px 0px rgba(186, 186, 47,0.75)',
                    ],
                    scale: [1, 1.05, 1],
                  },
                  transition: {
                    type: 'keyframes',
                    stiffness: 260,
                    damping: 20,
                    repeat: Infinity,
                    repeatDelay: 0,
                    duration: 2.5,
                  },
                }}
                sx={{ whiteSpace: 'nowrap' }}
              >
                {translate('sign_up')}
              </GoldBorderButton>
            </>
          ) : (
            <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
              {isDesktop && (
                <GradientBorderWrapper sx={{ display: 'flex', borderRadius: '6px', p: 0 }}>
                  <Stack
                    direction="row"
                    spacing={'2px'}
                    sx={{
                      p: '5px',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <Image src={require('../../../assets/home/bitcoin.png')} sx={{ width: '20px', height: '20px' }} />
                    <Typography
                      onClick={() =>
                        dispatch(
                          openTransactionsDialog({
                            open: true,
                            isDeposit: true,
                          })
                        )
                      }
                      sx={{
                        cursor: 'pointer',
                        color: palette.header.balance_button.color,
                      }}
                    >
                      {`${memberBalance?.currencyCode && isDesktop ? memberBalance?.currencyCode : ''} ${
                        memberBalance?.displayBalance ? fNumber(memberBalance?.displayBalance) : '0'
                      }`}
                    </Typography>
                  </Stack>
                  <GradientBorderWrapper
                    onClick={() =>
                      dispatch(
                        openTransactionsDialog({
                          open: true,
                          isDeposit: true,
                        })
                      )
                    }
                    component={Stack}
                    direction={'row'}
                    spacing={'2px'}
                    alignItems="center"
                    sx={{ borderRadius: '6px', p: '5px', cursor: 'pointer', backgroundColor: 'black', margin: '-2px' }}
                  >
                    <Iconify icon={'game-icons:wallet'} width={20} height={20} sx={{ color: 'white' }} />
                    <Typography
                      sx={{ color: palette.header.deposit_btn.color, display: { sm: 'unset', display: 'none' } }}
                    >
                      {translate('deposit')}
                    </Typography>
                  </GradientBorderWrapper>
                </GradientBorderWrapper>
              )}
              {/* <button
              style={{
                backgroundColor: '#ff3141',
                border: 'none',
                padding: '3px 10px',
                borderRadius: '10px',
                cursor: 'pointer',
              }}
              onClick={() =>
                dispatch(
                  openTransactionsDialog({
                    open: true,
                    isDeposit: true,
                  })
                )
              }
            >
              <Iconify icon={'fluent:wallet-24-filled'} width={20} height={20} sx={{ color: 'white' }} />
            </button> */}

              <NotificationPopover />

              <AccountPopover />
            </Stack>
          )}
        </Stack>

        {isOpenLoginSignupDialog.open && (
          <LoginSignupDialog setOpenForgotPasswordDialog={setOpenForgotPasswordDialog} />
        )}

        {openForgotPasswordDialog && (
          <ForgotPasswordDialog
            openForgotPasswordDialog={openForgotPasswordDialog}
            setOpenForgotPasswordDialog={setOpenForgotPasswordDialog}
          />
        )}

        {openResetPasswordDialog && (
          <ResetPasswordDialog
            openResetPasswordDialog={openResetPasswordDialog}
            setOpenResetPasswordDialog={setOpenResetPasswordDialog}
          />
        )}
      </Toolbar>
    </RootStyle>
  );
}
