import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router';

const DomainWrapper = () => {
  const params = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const domains = process.env.REACT_APP_SUB_DOMAINS ? process.env.REACT_APP_SUB_DOMAINS.split(',') : '';

    if (!params?.domain || !domains.includes(params?.domain)) {
      const defaultCountry = domains[0];
      navigate(`/${defaultCountry}`);
    }
  }, [navigate, params]);

  return <Outlet />;
};

export default DomainWrapper;
