/* eslint-disable camelcase */
// @mui
import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { getYear } from 'date-fns';
import Iconify from '../../components/Iconify';
import Image from '../../components/Image';
import { useSelector } from '../../redux/store';
import useLocales from '../../hooks/useLocales';
import palette from '../../theme/palette';

const StyledTypography = styled(Typography)(({ theme }) => ({
  '&': {
    color: 'white',
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'uppercase',
    marginTop: '2rem',
  },
}));

const FooterText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '.75rem',
  textAlign: 'center',
  cursor: 'pointer',
  color: '#f2c556',
  margin: 0,
}));

const Footer = () => {
  const { siteSettings } = useSelector((x) => x.lookup);

  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const navigate = useNavigate();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar('Copied!');
  };

  const copyrightText = useMemo(() => {
    if (siteSettings?.config_footer_copyright) {
      const newText = siteSettings?.config_footer_copyright
        .replace('[year]', getYear(new Date()))
        .replace('[name]', siteSettings?.config_name);

      return newText;
    }

    return '';
  }, [siteSettings?.config_footer_copyright, siteSettings?.config_name]);

  const openSocial = (link) => {
    window.open(
      link,
      '_blank'
      // 'noopener noreferrer'
    );
  };

  const importAll = (requireContext) => {
    return requireContext.keys().map(requireContext);
  };

  return (
    <Box sx={{ m: '20px auto 0', p: '20px 0px 100px', overflow: 'hidden', background: '#000' }}>
      {/* Player/Winner */}
      {/* <Grid container spacing={1}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Box sx={{ backgroundColor: '#343a45', borderRadius: '10px', p: '20px', textAlign: 'center' }}>
            <Stack direction="column" alignItems={'center'} justifyContent={'center'}>
              <Typography sx={{ fontSize: '24px', color: '#437bde', fontWeight: 'bolder' }}>915, 190</Typography>
              <Stack direction="row" justifyContent={'center'} alignItems={'center'} spacing={1}>
                <Iconify icon={'fa:group'} width={25} height={25} sx={{ color: 'rgb(152, 172, 208)' }} />
                <Typography sx={{ fontSize: '14px', color: '#98acd0', fontWeight: 'bolder' }}>Player</Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Box sx={{ backgroundColor: '#343a45', borderRadius: '10px', p: '20px', textAlign: 'center' }}>
            <Stack direction="column" alignItems={'center'}>
              <Typography sx={{ fontSize: '24px', color: '#437bde', fontWeight: 'bolder' }}>970, 957</Typography>
              <Stack direction="row" justifyContent={'center'} alignItems={'center'} spacing={1}>
                <Iconify
                  icon={'healthicons:award-trophy'}
                  width={25}
                  height={25}
                  sx={{ color: 'rgb(152, 172, 208)' }}
                />
                <Typography sx={{ fontSize: '14px', color: '#98acd0', fontWeight: 'bolder' }}>Player</Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid> */}

      <Container maxWidth="lg" sx={{ p: '35px 0px !important' }}>
        <Stack direction="row" alignItems={'center'} spacing={2} sx={{ ml: 2, color: '#f2c556' }}>
          <Iconify icon={'fa-solid:dice'} sx={{ width: '25px', height: '25px' }} />
          <Typography sx={{ fontWeight: 600, fontSize: '.85rem' }}>{translate('our_game_provides')}</Typography>
        </Stack>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2, px: 2 }}>
          <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {_gameProviders.map((item, index) => (
              <Grid key={index} item>
                <Image
                  src={item}
                  alt="Google"
                  sx={{
                    height: '40px',
                    width: '80px',
                    // height: { xl: '40px', lg: '40px', md: '40px', sm: '40px', xs: '40px' },
                    // width: { xl: '80px', lg: '80px', md: '80px', sm: '80px', xs: '80px' },
                    maxWidth: '130px',
                    '& img': {
                      objectFit: 'contain',
                    },
                  }}
                  ratio={'4 * 3'}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <Container maxWidth="lg" sx={{ p: 2 }}>
        <Grid container spacing={{ sm: 1, xs: 2 }}>
          <Grid item sm={12} xs={12}>
            <Stack direction={'column'} spacing={1}>
              <Image
                src={require('../../assets/brand-logo.png')}
                sx={{
                  height: '40px',
                  width: 'auto',
                  maxWidth: '130px',
                  mx: 'auto',
                  '& img': {
                    objectFit: 'contain',
                  },
                }}
              />
            </Stack>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Stack direction={'column'} spacing={1} alignItems="center">
              <FooterText sx={{ fontSize: '.95rem', fontWeight: 900, textTransform: 'uppercase' }}>
                {translate('helpful_links')}
              </FooterText>
              <Stack direction={'row'} spacing={1}>
                <FooterText>{translate('all_games')}</FooterText>
                <FooterText>Slots</FooterText>
                <FooterText>Live Casino</FooterText>
              </Stack>
            </Stack>
          </Grid>
          <Grid item sm={12} xs={12} mt={2}>
            <Stack direction={'column'} spacing={1} alignItems="center">
              <FooterText sx={{ fontSize: '.95rem', fontWeight: 900, textTransform: 'uppercase' }}>
                {translate('platform')}
              </FooterText>
              <Stack direction={'row'} spacing={1}>
                <FooterText>{translate('support')}</FooterText>
                <FooterText>FAQ</FooterText>
                <FooterText>Slots</FooterText>
                <FooterText>{translate('partnership_program')}</FooterText>
                <FooterText>{translate('help_center')}</FooterText>
              </Stack>
            </Stack>
          </Grid>
          <Grid item sm={12} xs={12} mt={2}>
            <Stack direction={'column'} spacing={1} alignItems="center">
              <FooterText sx={{ fontSize: '.95rem', fontWeight: 900, textTransform: 'uppercase' }}>
                {translate('about_us')}
              </FooterText>
              <Stack direction={'row'} spacing={1} alignItems="center" justifyContent={'center'}>
                <FooterText
                  onClick={() =>
                    navigate('tnc', {
                      state: {
                        pageKey: 'rgDocumentBerry96',
                      },
                    })
                  }
                >
                  {translate('responsible_gaming')}
                </FooterText>
                <FooterText
                  onClick={() =>
                    navigate('tnc', {
                      state: {
                        pageKey: 'ppDocumentBerry96',
                      },
                    })
                  }
                >
                  {translate('privacy_policy')}
                </FooterText>
                <FooterText
                  onClick={() =>
                    navigate('tnc', {
                      state: {
                        pageKey: 'tncDocumentBerry96',
                      },
                    })
                  }
                >
                  {translate('terms_and_condition')}
                </FooterText>
              </Stack>
            </Stack>
          </Grid>
          <Grid item sm={12} xs={12} mt={2}>
            <Stack direction="column" spacing={1} alignItems="center">
              <FooterText sx={{ fontSize: '.95rem', fontWeight: 900, textTransform: 'uppercase' }}>
                {translate('community')}
              </FooterText>
              <Stack direction={'row'} spacing={1} alignItems="center" justifyContent={'center'}>
                <FooterText
                  onClick={() =>
                    navigate('tnc', {
                      state: {
                        pageKey: 'rgDocumentBerry96',
                      },
                    })
                  }
                >
                  {translate('responsible_gaming')}
                </FooterText>
                <FooterText
                  onClick={() =>
                    navigate('tnc', {
                      state: {
                        pageKey: 'ppDocumentBerry96',
                      },
                    })
                  }
                >
                  {translate('privacy_policy')}
                </FooterText>
                <FooterText
                  onClick={() =>
                    navigate('tnc', {
                      state: {
                        pageKey: 'tncDocumentBerry96',
                      },
                    })
                  }
                >
                  {translate('terms_and_condition')}
                </FooterText>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg" sx={{ p: 2, mt: '4rem' }}>
        <Typography
          variant={'h6'}
          sx={{
            textDecoration: 'underline',
            textAlign: 'center',
            marginBottom: 1,
          }}
        >
          {translate('we_accept_payment_methods')}
        </Typography>
        <Box
          sx={{
            borderTop: '1.5px solid white',
            borderBottom: '1.5px solid white',
            width: 'fit-content',
            display: 'block',
            mx: 'auto',
            py: 1,
            mb: 4,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid container spacing={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {_paymentMethods.map((item, index) => (
                <Grid key={index} item>
                  <Image
                    src={item}
                    alt="Banks"
                    sx={{
                      height: { xs: '10.5vw', sm: '11.5vw', md: '55px', lg: '60px' },
                      width: { xs: '10.5vw', sm: '11.5vw', md: '55px', lg: '60px' },
                      '& img': {
                        objectFit: 'contain',
                      },
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        <Typography
          variant={'h6'}
          sx={{
            textDecoration: 'underline',
            textAlign: 'center',
            marginBottom: 1,
          }}
        >
          {translate('we_accept_below_bank')}
        </Typography>
        <Box
          sx={{
            borderTop: '1.5px solid white',
            borderBottom: '1.5px solid white',
            width: 'fit-content',
            display: 'block',
            mx: 'auto',
            py: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid container spacing={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {_banks.map((item, index) => (
                <Grid key={index} item>
                  <Image
                    src={item}
                    alt="Banks"
                    sx={{
                      height: { xs: '10.5vw', sm: '11.5vw', md: '55px', lg: '60px' },
                      width: { xs: '10.5vw', sm: '11.5vw', md: '55px', lg: '60px' },
                      '& img': {
                        objectFit: 'contain',
                      },
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        <StyledTypography sx={{ fontSize: '9px !important' }}>{copyrightText}</StyledTypography>
      </Container>
    </Box>
  );
};

export default Footer;

const _gameProviders = [
  require('../../assets/brand-logo.png'),
  require('../../assets/powered/pgsoft.png'),
  require('../../assets/powered/pragmatic_play.png'),
  require('../../assets/powered/jili.png'),
  require('../../assets/powered/evolution.png'),
  require('../../assets/powered/evoplay.png'),
  require('../../assets/powered/live22.png'),
  require('../../assets/powered/microgaming.png'),
  require('../../assets/powered/netent.png'),
  require('../../assets/powered/playtech.png'),
  require('../../assets/powered/quickspin.png'),
  require('../../assets/powered/wm.png'),
];

const _paymentMethods = [
  require('../../assets/home/banks/boost.png'),
  require('../../assets/home/banks/celcom.png'),
  require('../../assets/home/banks/digi.png'),
  require('../../assets/home/banks/duit.png'),
  require('../../assets/home/banks/grabpay.png'),
  require('../../assets/home/banks/gx.png'),
  require('../../assets/home/banks/maxis.png'),
  require('../../assets/home/banks/mobile.png'),
  require('../../assets/home/banks/shopee_pay.png'),
  require('../../assets/home/banks/touch_go.png'),
];

const _banks = [
  require('../../assets/home/banks/affin.png'),
  require('../../assets/home/banks/alliance.png'),
  require('../../assets/home/banks/ambank.png'),
  require('../../assets/home/banks/bank_islam.png'),
  require('../../assets/home/banks/bsn.png'),
  require('../../assets/home/banks/cimb.png'),
  require('../../assets/home/banks/hongleong.png'),
  require('../../assets/home/banks/m.png'),
  require('../../assets/home/banks/maybank.png'),
  require('../../assets/home/banks/public.png'),
  require('../../assets/home/banks/rhb.png'),
];
