// Import the functions you need from the SDKs you need
import { PhoneAuthProvider, RecaptchaVerifier, getAuth, signInWithCredential } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { FIREBASE_API } from '../config';

class FirebaseUtil {
  constructor(config) {
    this.app = null;
    this.auth = null;
    this.recaptchaVerifier = null;
    this.isInitialized = false;
    this.initializeFirebase(config);
  }

  initializeFirebase(config) {
    if (!this.app && !this.auth) {
      try {
        this.app = initializeApp(config);
        this.auth = getAuth(this.app);
        this.isInitialized = true;
      } catch (error) {
        console.warn('Firebase Config is invalid!');
      }
    } else {
      console.warn('Firebase is already initialized.');
    }
  }

  initVerifier() {
    try {
      if (!this.recaptchaVerifier) {
        if (document && !document.getElementById('recaptcha-container')) {
          const recaptchaContainer = document.createElement('div');
          recaptchaContainer.id = 'recaptcha-container';
          document.body.appendChild(recaptchaContainer);
        } else throw new Error('Recaptcha Container not rendered!');

        this.recaptchaVerifier = new RecaptchaVerifier(
          'recaptcha-container',
          {
            size: 'invisible',
            callback: () => {},
            'expired-callback': () => {},
          },
          this.auth
        );
      } else {
        console.warn('Firebase Recaptcha Verifier already initialized.');
      }
    } catch (error) {
      console.warn('Firebase Config is invalid!');
    }
  }

  getIsInitialized() {
    return this.isInitialized;
  }

  getRecaptchaVerifier() {
    return this.recaptchaVerifier;
  }

  resetVerifier() {
    // eslint-disable-next-line no-undef
    if (this.recaptchaVerifier) grecaptcha.reset();
  }

  handleSendOTP(phoneNumber) {
    console.log(`phoneNumber = ${phoneNumber}`);
    console.log(`phoneNumber = ${phoneNumber}`);
    const phoneProvider = new PhoneAuthProvider(this.auth);
    return phoneProvider.verifyPhoneNumber(phoneNumber, this.recaptchaVerifier);
  }

  handleVerifyOTP(verificationId, code) {
    const credential = PhoneAuthProvider.credential(verificationId, code);
    return signInWithCredential(this.auth, credential);
  }
}

const firebaseUtil = new FirebaseUtil(FIREBASE_API);

export default firebaseUtil;
