import { useEffect, useState } from 'react';
import { Box, Divider, Grid, Stack, Typography, styled } from '@mui/material';
import TelegramLoginButton from 'react-telegram-login';
import { useSnackbar } from 'notistack';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { useNavigate } from 'react-router-dom';
import useLocales from '../../../../hooks/useLocales';

import { useDispatch, useSelector } from '../../../../redux/store';
import { openLoginSignup } from '../../../../redux/slices/authPages';

import Login from './Login';
import Register from './Register';
import CustomModal from '../../../../components/CustomModal';
import Image from '../../../../components/Image';
import conObj from '../../../../utils/connection-assistant';
import useAuth from '../../../../hooks/useAuth';

import palette from '../../../../theme/palette';
import { telegramId } from '../../../../config';

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontFamily: 'sans-serif',
  fontWeight: 500,
  color: palette.visitor.dialog.footerText,
  cursor: 'pointer',
}));

const SocialIconCon = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 'fit-content !important',
  display: 'flex !important',
  alignItems: 'center !important',
  justifyContent: 'center !important',
  cursor: 'pointer !important',
}));

const CustomHr = ({ style }) => (
  <hr style={{ borderTop: `1.5px solid ${palette.visitor.dialog.hr}`, borderBottom: 'none', height: 1, ...style }} />
);

const SocialIcon = styled(Image)(({ theme }) => ({
  width: '35px',
  height: '35px',
  borderRadius: '5px',
  cursor: 'pointer',
}));

const LoginSignupDialog = ({ setOpenForgotPasswordDialog }) => {
  const { login } = useAuth();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isOpenLoginSignupDialog } = useSelector((x) => x.authPages);

  const [activeTab, setActiveTab] = useState('1');

  const [authData, setAuthData] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  useEffect(() => {
    if (isOpenLoginSignupDialog?.isLogin) setActiveTab('1');
    if (!isOpenLoginSignupDialog?.isLogin) setActiveTab('2');
  }, []);

  const handleTelegramLogin = () => {
    window?.Telegram?.Login.auth({ bot_id: telegramId, request_access: true }, (data) => {
      if (!data) {
        // authorization failed
      }

      // Here you would want to validate data like described there https://core.telegram.org/widgets/login#checking-authorization
      thirdPartyAuth(data);
    });
  };

  const thirdPartyAuth = async (response, type = 'telegram') => {
    try {
      const apiResponse = await conObj.post('users/tpp_login.json', {
        'user_third_party_login-data': JSON.stringify(response),
        'user_third_party_login-platform': type,
      });

      let isSuccess = false;

      const responseData = apiResponse?.data?.data;

      console.log(response, responseData?.registerNow);

      if (responseData?.registerNow) {
        setAuthData({ ...response, platform: type });
        setActiveTab('2');
        isSuccess = true;
      }

      if (!responseData?.registerNow && responseData?.token) {
        await login(
          {},
          {
            token: responseData?.token,
            firstName: responseData?.first_name,
            username: responseData?.username,
            fullName: responseData?.full_name,
            lastName: responseData?.last_name,
            email: responseData?.email,
          }
        );
        isSuccess = true;
      }

      if (!isSuccess) {
        throw new Error(`Login to ${type} failed!`);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  return (
    <CustomModal
      fullWidth
      maxWidth="xs"
      open={isOpenLoginSignupDialog.open}
      onClose={() => dispatch(openLoginSignup({ open: false, isLogin: true }))}
    >
      <Grid 
        container 
        sx={{ 
          background: 'linear-gradient(to bottom, rgba(30, 30, 30, 1) 0%, rgba(0, 0, 0, 1) 100%)',
          borderRadius: "18px"
        }}
      >
        {/* <Grid item sm={6} xs={12}>
          <Image
            src={require('../../../../assets/visitor/visitor_banner.png')}
            sx={{ display: { sm: 'block', xs: 'none' } }}
          />
          <Image
            src={require('../../../../assets/visitor/visitor_banner_mobile.png')}
            sx={{ display: { sm: 'none', xs: 'block' } }}
          />
        </Grid> */}
        <Grid
          item
          xs={12}
          sx={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: { sm: '714px', xs: 'unset' } }}
        >
          <Box sx={{ p: 3 }}>
            {activeTab === '1' ? (
              <Login
                setOpenForgotPasswordDialog={setOpenForgotPasswordDialog}
                setActiveTab={setActiveTab}
                setAuthData={setAuthData}
              />
            ) : (
              <Register setActiveTab={setActiveTab} authData={authData} />
            )}
            {/* <Box>
              <Stack direction="row" alignItems={'center'} sx={{ mt: 4 }}>
                {!authData || activeTab === '1' ? (
                  <>
                    <CustomHr style={{ flexGrow: 1 }} />
                    <Typography sx={{ color: palette.visitor.dialog.loginDirectly, fontSize: '14px', px: 1 }}>
                      {translate('login_directly')}
                    </Typography>
                    <CustomHr style={{ flexGrow: 1 }} />
                  </>
                ) : (
                  ''
                )}
              </Stack>
            </Box>
            {!authData || activeTab === '1' ? (
              <Box>
                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={3} sx={{ p: 1 }}>
                  <SocialIconCon onClick={() => handleTelegramLogin()} className="rainbow-box">
                    <SocialIcon src={require('../../../../assets/visitor/telegram.png')} />
                  </SocialIconCon>
                  <TelegramLoginButton
                    className="telegram-btn"
                    dataOnauth={(response) => thirdPartyAuth(response)}
                    botName="Brl7777StgBot"
                  />
                </Stack>
              </Box>
            ) : (
              ''
            )} */}

            <Divider sx={{ mt: 2 }} />

            <FooterText 
              sx={{ 
                textAlign: 'center',
                cursor: 'pointer'
              }}
              onClick={() => {
                navigate('tnc', {
                  state: {
                    pageKey: 'ppDocumentBerry96',
                  },
                })
                dispatch(openLoginSignup({ open: false }))
              }}
            >
              {translate('by_signing_in')}{' '}
              <span style={{ color: '#edb001', fontWeight: 600 }}>{translate('privacy_policy')}</span>
            </FooterText>
          </Box>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default LoginSignupDialog;
