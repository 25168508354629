export default function getPercentage(currentValue, maxValue) {
  if (maxValue === 0) {
    return 0;
  }

  // Calculate the percentage
  let percentage = (currentValue / maxValue) * 100;

  // Round the percentage to two decimal places (optional)
  percentage = Math.round(percentage * 100) / 100;

  return percentage;
}
