import React, { useEffect, useMemo, useState } from 'react';
// @mui
import { Accordion, AccordionActions, AccordionSummary, Box, Button, MenuItem, Stack, Typography } from '@mui/material';
// hooks
import { useParams } from 'react-router';
// components
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import Iconify from '../../../components/Iconify';
import { DEFAULT_COUNTRY_SELECTIONS, allCountry } from '../../../config';
import useLocales from '../../../hooks/useLocales';
import useResponsive from '../../../hooks/useResponsive';

// ----------------------------------------------------------------------

export default function CountryPopover({ sx = {}, CustomBtn, PopoverProps = {} }) {
  const { allLangs, currentLang, onChangeLang } = useLocales();
  const isSm = useResponsive('down', 'sm');

  const [open, setOpen] = useState(null);

  const params = useParams();

  const currentCountry = useMemo(() => {
    if (params?.domain) {
      const findCountry = allCountry.find((x) => x.domain === params.domain);

      if (findCountry) {
        return findCountry;
      }
    }

    return allCountry.find((x) => x.domain === DEFAULT_COUNTRY_SELECTIONS[0]);
  }, [params]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeCountry = (country, newLang) => {
    handleClose();

    if (country?.domain === currentCountry?.domain) {
      localStorage.setItem(`lang${params?.domain?.toUpperCase()}`, newLang);
      onChangeLang(newLang);
      window.location.reload();
    } else {
      localStorage.setItem(`lang${country?.domain?.toUpperCase()}`, newLang);
      window.open(
        `/${country?.domain}/auth/login`,
        '_blank'
        // 'noopener noreferrer'
      );
    }
  };

  const displayCurrentLang = () => {
    const curLang = allLangs?.find((f) => f?.value === localStorage.getItem('i18nextLng'));
    return curLang?.shortLabel ? curLang?.shortLabel?.toUpperCase() : curLang?.label?.toUpperCase();
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={(e) => handleOpen(e)}>
        <Box sx={{ flex: 1 }}>
          {CustomBtn ? (
            React.cloneElement(CustomBtn, { title: currentCountry?.label })
          ) : (
            <Button
              variant={'text'}
              // onClick={handleOpen}
              startIcon={
                <Image
                  disabledEffect
                  src={currentCountry?.icon}
                  alt={currentCountry?.label}
                  sx={{ width: 28, height: 14 }}
                />
              }
              sx={sx}
              size="small"
            >
              <Typography sx={{ color: '#fff' }} fontWeight={700}>
                {displayCurrentLang()}
              </Typography>
            </Button>
          )}
        </Box>
        <Iconify icon={'iconamoon:arrow-down-2-fill'} />
      </Box>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 'fit-content',
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
        {...PopoverProps}
      >
        <Stack spacing={0.75}>
          {allCountry.map((option) => (
            <MenuItem key={option.domain} selected={option?.domain === currentCountry?.domain}>
              <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, height: 14, mr: 2 }} />
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={0.75}>
                {allLangs
                  ?.filter((f) => f?.value === 'en' || f?.domain === option?.domain)
                  ?.map((item, index) => (
                    <>
                      {index !== 0 && (
                        <Typography
                          key={index}
                          sx={{ mx: '5px', color: 'red', fontWeight: 600, display: { xs: 'none', sm: 'flex' } }}
                        >
                          /
                        </Typography>
                      )}
                      <Typography
                        key={index}
                        onClick={() => {
                          handleChangeCountry(option, item?.value);
                        }}
                        sx={{
                          cursor: 'pointer',
                          color:
                            option?.domain === currentCountry?.domain &&
                            localStorage.getItem('i18nextLng') === item?.value
                              ? '#fff'
                              : '#a2aabd',
                          fontWeight:
                            option?.domain === currentCountry?.domain &&
                            localStorage.getItem('i18nextLng') === item?.value
                              ? 500
                              : 400,
                        }}
                      >
                        {item?.label}
                      </Typography>
                    </>
                  ))}
              </Stack>
            </MenuItem>
          ))}
          {/* {allCountry.map((option) => (
            <MenuItem
              key={option.value}
              selected={option?.domain === currentCountry?.domain}
              onClick={() => {
                handleChangeCountry(option);
              }}
            >
              <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

              {option.label}
            </MenuItem>
          ))} */}
        </Stack>
      </MenuPopover>
    </>
  );
}
