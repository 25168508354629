import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { IconButton, InputAdornment, TextField, InputBase, styled } from '@mui/material';
import { useState } from 'react';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

const InputBorder = styled('div')(() => ({
  height: 'fit-content',
  position: 'relative',
  padding: 2,
  borderRadius: '8px',
  background: `linear-gradient(90deg, #BC8001 0%, #C8941C 17.62%, #FAEB9A 26.88%, #C8941C 32.84%, #C8941C 38.5%, #FAEB9A 43.4%, #FAEB9A 52.96%, #C8941C 59.75%, #C8941C 63.11%, #FAEB9A 72.02%, #C8941C 79.16%, #BC8001 100%)`,
}));


const Input = styled(TextField)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  fontFamily: 'sans-serif',
  flexGrow: 1,
  color: '#fff',
  backgroundColor: "#000",
  borderRadius: '8px',
  '& input': {
    color: '#fff',
    fontWeight: 600,
    zIndex: 1,
    '&::placeholder': {
      color: 'rgb(255, 255, 255, .5)',
      zIndex: 2,
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiInputLabel-root': {
    transition: 'color 0s, opacity 0s',
    '&.Mui-focused': {
      color: 'transparent',
      opacity: 0,
    },
  },
  '& .MuiInputLabel-shrink': {
    color: 'transparent',
  },
  
}));

RHFPassword.propTypes = {
  name: PropTypes.string,
};

export default function RHFPassword({ name, ...other }) {
  const { control } = useFormContext();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <InputBorder>
          <Input
            {...field}
            {...other}
            fullWidth
            variant="outlined"
            error={!!error}
            helperText={error?.message}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" tabIndex={-1}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} sx={{color: '#edb001'}} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </InputBorder>
      )}
    />
  );
}
