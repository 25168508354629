import { LoadingButton } from '@mui/lab';
import { Box, styled } from '@mui/material';

// export const GradientBorderWrapper = styled(Box)({
//   fontWeight: 700,
//   backgroundColor: 'transparent',
//   background:
//     'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(56,56,56,1) 35%, rgba(56,56,56,1) 65%, rgba(0,0,0,1) 100%)',
//   color: 'white',
//   border: '2px solid transparent',
//   backgroundClip: 'padding-box',
//   position: 'relative',
//   '&:before': {
//     content: '""',
//     position: 'absolute',
//     top: 0,
//     right: 0,
//     left: 0,
//     bottom: 0,
//     zIndex: -1,
//     margin: '-2px',
//     borderRadius: 'inherit',
//     background:
//       'linear-gradient(90deg, rgba(196,150,59,1) 0%, rgba(221,198,116,1) 25%, rgba(221,198,116,1) 45%, rgba(191,149,59,1) 100%)',
//   },
// });

export const GradientBorderWrapper = ({ children, wrapperProps = {}, sx = {}, ...others }) => (
  <Box
    sx={{
      padding: '2px',
      border: 'none',
      borderRadius: 1,
      background:
        'linear-gradient(90deg, rgba(196,150,59,1) 0%, rgba(221,198,116,1) 25%, rgba(221,198,116,1) 45%, rgba(191,149,59,1) 100%)',
      ...(wrapperProps?.sx || {}),
    }}
    {...wrapperProps}
  >
    <Box
      sx={{
        background:
          'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(56,56,56,1) 35%, rgba(56,56,56,1) 65%, rgba(0,0,0,1) 100%)',
        borderRadius: 'inherit',
        ...sx,
      }}
      {...others}
    >
      {children}
    </Box>
  </Box>
);

export const GoldBorderButton = ({ children, sx = {}, wrapperSx = {}, wrapperProps = {}, ...others }) => (
  <Box
    sx={{
      background:
        'linear-gradient(90deg, rgba(187,143,52,1) 0%, rgba(187,143,52,1) 16%, rgba(247,235,164,1) 20%, rgba(247,235,164,1) 27%, rgba(187,143,52,1) 32%, rgba(187,143,52,1) 36%, rgba(247,235,164,1) 41%, rgba(247,235,164,1) 48%, rgba(187,143,52,1) 54%, rgba(187,143,52,1) 60%, rgba(247,235,164,1) 66%, rgba(247,235,164,1) 75%, rgba(187,143,52,1) 83%, rgba(187,143,52,1) 100%)',
      padding: '1px',
      borderRadius: 1,
      border: 'none',
      ...wrapperSx,
    }}
    {...wrapperProps}
  >
    <LoadingButton
      sx={{
        fontWeight: 700,
        backgroundColor: 'transparent',
        backgroundImage: 'linear-gradient(180deg, #292929 0%, #020202 100%)',
        color: 'white',
        borderRadius: 'inherit',
        ...sx,
      }}
      {...others}
    >
      {children}
    </LoadingButton>
  </Box>
);
