// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const bn = {
  no: `না`,
  select_country_code: `দেশের কোড নির্বাচন করুন`,
  balance: `ব্যালেন্স`,
  requirement: `প্রয়োজনীয়তা`,
  deposit_from_bank: `ব্যাংক থেকে আমানত`,
  game: `খেলা`,
  games: `খেলা`,
  sport: `খেলা`,
  center: `কেন্দ্র`,
  mines: `মাইনস`,
  crash: `ক্র্যাশ`,
  lucky_colour: `ভাগ্যবান রঙ`,
  dice: `ডাইস`,
  limbo: `লিম্বো`,
  lucky_gift: `ভাগ্যবান উপহার`,
  all_games: `সমস্ত খেলা`,
  game_history: `খেলার ইতিহাস`,
  coupon_code: `কুপন কোড`,
  telegram: `টেলিগ্রাম`,
  customer_service: `গ্রাহক সেবা`,
  player: `খেলোয়াড়`,
  winner: `বিজয়ী`,
  all_bets: `সমস্ত বাজি`,
  my_bets: `আমার বাজি`,
  name: `নাম`,
  time: `সময়`,
  bet_amount: `বাজির পরিমাণ`,
  prediction: `পূর্বানুমান`,
  payout: `পেআউট`,
  login: `লগইন`,
  register: `রেজিস্টার`,
  username_placeholder: `ব্যবহারকারীর নাম`,
  please_input_your_x: `দয়া করে আপনার {{x}} ইনপুট করুন`,
  affiliate_referral: `এফিলিয়েট / রেফারেল আইডি (ঐচ্ছিক)`,
  captcha: `ক্যাপচা`,
  forgot_password: `পাসওয়ার্ড ভুলে গেছেন`,
  minor_note: `এই সাইট দেখতে, নিশ্চিত করুন যে আপনি 18 বছরের বেশি এবং সম্মত`,
  terms_of_service: `সেবা প্রয়োগের শর্তাবলী`,
  by_signing_in: 'সাইন ইন বা সাইন আপ করে, আপনি আমাদের সাথে সম্মত',
  dont_have_account: `আপনার কোনও অ্যাকাউন্ট নেই?`,
  sign_up: `সাইন আপ`,
  username: `ব্যবহারকারীর নাম`,
  email: `ইমেল`,
  full_name: `পুরো নাম`,
  confirm_18:
    'রেজিস্টার বাটনে ক্লিক করে, আমি এখানে ঘোষণা করছি যে আমি ১৮ বছরের বেশি এবং আপনার শর্তাবলী পড়ে এবং গৃহীত করেছি।',
  already_have_account: 'আগেই একটি অ্যাকাউন্ট আছে?',
  share: 'শেয়ার',
  deposit: 'আমানত',
  bonus: 'বোনাস',
  app: 'অ্যাপ',
  load_more: 'আরও লোড করুন',
  details: 'বিস্তারিত',
  helps: 'সাহায্য',
  kyc_policy: 'KYC নীতি',
  privacy_policy: 'গোপনীয়তা নীতি',
  responsible_gaming: 'দায়িত্বশীল গেমিং',
  terms_and_condition: 'শর্তাবলী',
  faq: 'প্রায়শ্চিত্ত্য প্রশ্ন',
  about_us: 'আমাদের সম্পর্কে',
  amount: 'পরিমাণ',
  extra: 'অতিরিক্ত',
  deposit_bonus: 'আমানত বোনাস',
  do_not_participate: 'প্রচারণায় অংশগ্রহণ করবেন না',
  payment_problem: 'পেমেন্ট সমস্যা',
  click_for_help: 'সাহায্যের জন্য ক্লিক করুন',
  deposit_notes: 'আমানত নোট',
  available_balance: 'উপলব্ধ ব্যালেন্স',
  withdraw: 'উত্তোলন',
  withdrawal_notes: 'উত্তোলন নোট',
  withdrawal_note1: 'বর্তমান ভিআইপি-তে উত্তোলনের সংখ্যা',
  withdrawal_note1_1: 'vip',
  withdrawal_note2: 'উত্তোলন ফি:',
  min: 'সর্বনিম্ন:',
  max: 'সর্বাধিক:',
  opening_times: 'খোলামেলা সময়',
  withdrawal_note3: '1. সবচেয়ে দ্রুত উত্তোলন সময় 10 মিনিটের মধ্যে',
  withdrawal_note4: `2.আপনার উত্তোলনের তথ্য সঠিক হতে নিশ্চিত করুন, যদি উত্তোলনের তথ্য ভুল থাকে তাহলে আপনার অর্থ হারাতে পারে।`,
  withdrawal_note5: `3.আপনার যদি কোনো প্রশ্ন থাকে তবে আমাদের সাথে যোগাযোগ করতে পারেন।`,
  notifications: `বিজ্ঞপ্তি`,
  invalid_x: `অবৈধ {{x}}`,
  captcha_not_match: `ক্যাপচা মিলছে না`,
  invalid_x_must_be_atleast_y_characters: `অবৈধ {{x}}, অন্তত {{y}} টি অক্ষর হতে হবে`,
  x_is_required: `{{x}} প্রয়োজন`,
  phone_number: 'ফোন নম্বর',
  phone_num_x_max: `ফোন নম্বর সর্বাধিক {{x}} সংখ্যার হতে পারে`,
  email_sent: `ইমেইল পাঠানো হয়েছে, দয়া করে আপনার ইমেইল চেক করুন।`,
  enter_valid_email: `দয়া করে একটি সঠিক ইমেইল লিখুন।`,
  confirm: `অনুমোদন`,
  password_atleast_6_characters: `পাসওয়ার্ড অন্তত 6 টি অক্ষর হতে হবে।`,
  password_reset_success: `পাসওয়ার্ড রিসেট সফল হয়েছে!`,
  new_password: `নতুন পাসওয়ার্ড`,
  bank: 'ব্যাংক',
  bank_transfer: `ব্যাংক ট্রান্সফার`,
  bank_name: `ব্যাংকের নাম`,
  account_name: `অ্যাকাউন্টের নাম`,
  account_number: `অ্যাকাউন্ট নম্বর`,
  pix_number: `PIX নম্বর`,
  drop_or_select: `ফাইল পোস্ট বা নির্বাচন করুন`,
  drop_files_here: `ফাইলগুলি এখানে ড্রপ করুন`,
  transfer_to: `ট্রান্সফার করুন`,
  upload_receipt: `রশিদ আপলোড করুন`,
  select_bonus: `বোনাস নির্বাচন করুন`,
  no_promotion: `কোনো প্রচারণা নেই`,
  please_upload_receipt: `দয়া করে রশিদ আপলোড করুন`,
  bank_account: `ব্যাংক অ্যাকাউন্ট`,
  add_account: `অ্যাকাউন্ট যোগ করুন`,
  account_holder_name: `অ্যাকাউন্ট ধারকের নাম`,
  name_of_the_account_holder: `অ্যাকাউন্ট ধারকের নাম`,
  fill_in_bank_account: `ব্যাংক অ্যাকাউন্ট পূরণ করুন`,
  withdraw_information: `উত্তোলন তথ্য`,
  note: `নোট`,
  amount_withdrawn: `উত্তোলিত পরিমাণ`,
  amount_no_exceed_balance: `উত্তোলন পরিমাণ উপলব্ধ মোট টাকার চেয়ে বেশি হবে না`,
  minimum_withdraw_amount_is_x: `ন্যূনতম উত্তোলন পরিমাণ {{x}}`,
  transaction_history: `লেনদেন ইতিহাস`,
  date_from: `তারিখ থেকে`,
  date_to: `তারিখ পর্যন্ত`,
  account_name_must_be_4: `অ্যাকাউন্টের নাম অবশ্যই 4 অক্ষরের বেশি হতে হবে`,
  account_number_must_be_x: `অ্যাকাউন্ট নম্বর অবশ্যই 10-14 অক্ষরের হতে হবে`,
  minimum_length_is_x: `ন্যূনতম দৈর্ঘ্য {{x}}`,
  maximum_length_is_x: `সর্বাধিক দৈর্ঘ্য {{x}}`,
  payment_method: `পেমেন্ট মেথড`,
  mode: `মোড`,
  no_bank_account: `আপনার কোনো উত্তোলনের জন্য অ্যাকাউন্ট নেই, এখন যোগ করুন`,
  please_select_bank: `দয়া করে একটি ব্যাংক অ্যাকাউন্ট নির্বাচন করুন`,
  bet_history: `বেট হিস্ট্রি`,
  game_provider: `গেম প্রভাইডার`,
  bet_count: `বেট গণনা`,
  win_loss: `জিত/হার`,
  rebate: `রিবেট`,
  turnover: `টার্নওভার`,
  game_category: `গেম বিভাগ`,

  you_have_x_unread_messages: `আপনার আছে {{x}} অপঠিত মেসেজ`,
  deposit_amount_between_x_and_y: `জমা পরিমাণ {{x}} এবং {{y}} এর মধ্যে হতে হবে`,
  minimum_amount_is_x_y: `ন্যূনতম পরিমাণ {{x}}{{y}}`,

  // all
  all: `সব`,
  title: `শিরোনাম`,
  date: `তারিখ`,
  type: `ধরণ`,
  status: `অবস্থা`,
  remark: `মন্তব্য`,
  category: `বিভাগ`,
  please_select_x: `অনুগ্রহ করে {{x}} নির্বাচন করুন`,
  save: `সংরক্ষণ করুন`,

  change_password: `পাসওয়ার্ড পরিবর্তন করুন`,
  current_password: `বর্তমান পাসওয়ার্ড`,
  confirm_password: `নতুন পাসওয়ার্ড নিশ্চিত করুন`,
  submit: `জমা দিন`,
  password_changed: `পাসওয়ার্ড পরিবর্তন হয়েছে`,
  x_must_be_y_characters: `{{x}} অবশ্যই অন্তত {{y}} টি অক্ষর হতে হবে`,
  reset_password: `পাসওয়ার্ড রিসেট করুন`,
  casino: `ক্যাসিনো`,
  slots: `স্লট`,
  live_casino: `লাইভ ক্যাসিনো`,
  fishing: `ফিশিং`,
  sports: `স্পোর্টস`,
  cards: `কার্ড`,
  clear: `সাফ করুন`,
  search: `অনুসন্ধান`,
  new: `নতুন`,
  logout: `লগআউট`,
  choose_date: `তারিখ নির্বাচন করুন`,
  select_both_date: `দুটি তারিখ নির্বাচন করুন`,
  from: `থেকে`,
  to: `পর্যন্ত`,
  apply: `প্রয়োগ করুন`,
  promotions: `প্রচারণা`,
  learn_more: `আরও জানুন`,
  password_doesnt_match: `পাসওয়ার্ড মেলে না`,
  cancel: `বাতিল`,
  confirm_to_pay: `পেমেন্ট নিশ্চিত করুন`,
  deposit_amount: `জমা পরিমাণ`,
  loading_please_wait: `লোড হচ্ছে, অনুগ্রহ করে অপেক্ষা করুন`,
  account: `অ্যাকাউন্ট`,
  profile: `প্রোফাইল`,
  bank_list: `ব্যাংক তালিকা`,
  in_maintenance: `রক্ষণাবেক্ষণে`,
  birthday: `জন্মদিন`,
  birthday_bonus: `বোনাস পেতে আপনার জন্মদিন দিন! 🎉🎁`,
  referral_code: `রেফারেল কোড`,
  referral: `রেফারেল`,
  my_referral: `আমার রেফারেল`,
  my_claim: `আমার দাবি`,
  claim_profit: `লাভ দাবি`,
  available_profit: `উপলব্ধ লাভ`,
  claimed_profit: `দাবিত লাভ`,
  all_time_profit: `সর্বকালীন লাভ`,
  referral_note_on_x: `{{x}} এ আপনার বন্ধুরা খেলার প্রতিটি সময় লাভ অর্জন করুন`,
  tier: `টিয়ার`,
  commission: `কমিশন`,
  referred_member: `রেফার্ড সদস্য`,
  profit_earned: `লাভ অর্জন করা`,
  account_information: `অ্যাকাউন্ট তথ্য`,
  invite_friends: `বন্ধুদের আমন্ত্রণ জানান`,
  how_it_work: `এটা কিভাবে কাজ করে?`,
  invite_note: `আপনার রেফারেল কোড ব্যবহার করে আপনার বন্ধুরা নিবন্ধন করলে আপনি এক্সক্লুসিভ রেফারেল বোনাস পান!`,
  share_via: `শেয়ার করুন`,
  referral_link: `রেফারেল লিঙ্ক`,
  total_profit: `মোট লাভ`,
  copied: `অনুলিপি করা হয়েছে`,
  nothing_to_claim: `কোনো দাবি নেই`,
  claim_history: `দাবি ইতিহাস`,
  downline: `আমার ডাউনলাইন`,
  last_x_digit_bank_ref_number: `ব্যাংক রেফারেন্স নম্বরের শেষ {{x}} টি ডিজিট`,
  bank_ref_number_validation_x: `ব্যাংক রেফারেন্স নম্বর প্রয়োজন এবং এটি {{x}} অক্ষরের দৈর্ঘ্য হতে হবে`,
  sort: 'সাজান',
  login_directly: `সরাসরি লগইন করুন`,
  our_game_provides: `আমাদের গেম প্রভাইডার`,
  we_accept_below_bank: `আমরা নিম্নলিখিত ব্যাংকগুলি গ্রহণ করি`,
  we_accept_payment_methods: `আমরা পেমেন্ট পদ্ধতি গ্রহণ করি`,
  refer_and_reward: `রেফার এবং পুরস্কার`,
  linked_successfully_x: `{{x}} সাথে সফলভাবে লিঙ্ক করা হয়েছে! অনুগ্রহ করে প্রথম বারের জন্য আপনার অ্যাকাউন্ট নিবন্ধন করুন`,
  more_x_of_y: `{{x}}/{{y}} এর বেশি`,
  default: `ডিফল্ট`,
  promotion: `প্রচার`,
  daily_mission: 'ডেইলি মিশন',
  agent: 'এজেন্ট',
  contact_us: 'আমাদের সাথে যোগাযোগ করুন',
  download: 'ডাউনলোড',
  cash_claim: 'নগদ দাবি',
  demo: 'ডেমো',
  favorites: 'প্রিয়',
  popular: 'জনপ্রিয়',
  claim_bonus: `বোনাস দাবি`,
  insufficient_balance: `অপর্যাপ্ত ব্যালেন্স`,
  currently_displaying: 'বর্তমানে {{x}} {{y}} গেম প্রদর্শন করা হচ্ছে, মোট {{z}} টি',
  all_x: 'সমস্ত {{x}}',
  no_results: 'কোনো ফলাফল পাওয়া যায়নি',
  search_games: 'খেলার অনুসন্ধান করুন',
  please_scan_using_your: `অনুগ্রহ করে আপনার ব্যবহার করে স্ক্যান করুন`,
  vip_level: `ভিআইপি লেভেল`,
  bonus_direct: `বোনাস ডিরেক্ট`,
  rebate_level: `রিবেট লেভেল`,
  vip_header: `স্লট মেশিন, বোর্ড এবং কার্ড খেলা, ফিশিং, ভিডিও, স্পোর্টস এবং লটারি গেমগুলির সমস্ত বেট পারমানেন্টলি সংগ্রহ করা হবে। বৈধ বেট = স্থায়ী আয় এবং মানি ফোরচুন!`,
  vip_example: `
    উদাহরণ 1: সদস্যরা যারা 1 মিলিয়ন টাকা জমা করেছেন, সেগুলি ভিআইপি লেভেল 2 অর্জন করে, তাদের একটি বোনাস R$210 পেয়ে, 6 মিলিয়ন টাকা জমা করার পরে, তারা ভিআইপি লেভেল 3 অর্জন করে এবং R$800 এর বোনাস পান, একটি 4% রিবেট সহ`,
  activity_rules: `কার্যক্রমের নিয়ম:`,
  activity_rules_1: `1. সমস্ত বোনাসের জন্য x3 রোলওভার আবশ্যক হবে উত্তোলনের জন্য`,
  activity_rules_2: `2. দয়া করে মেয়াদ শেষ হওয়ার মধ্যে রিবেট বোনাস দাবি করুন, আপনি যদি দেরি করেন তবে এটি পরিত্যাগ হিসাবে গণ্য হবে;`,
  activity_rules_3: `3. সদস্যদের অংশগ্রহণ সিস্টেম দ্বারা স্বয়ংক্রিয়ভাবে গণ্য হয়, যদি কোনও এভাবে আপত্তি থাকে তবে বেরি96 এর অনুসন্ধানের ফলাফল প্রাধান্য থাকবে;`,
  activity_rules_4: `4. যদি আপনি মেম্বার অ্যাকাউন্ট / পাসওয়ার্ড ভুলে যান, তবে "24 ঘণ্টার অনলাইন কাস্টমার সার্ভিস" এ যোগাযোগ করে আপনার অ্যাকাউন্ট তথ্য পুনরুদ্ধার করার জন্য সাহায্য নিন;`,
  activity_rules_5: `5. বেরি96 এর অধিকার রয়েছে এই প্রচারণা যে সময়েই পরিবর্তন, বন্ধ বা বাতিল করতে পারে।`,
  activity_rules_6: `6. ফ্রি গেম সংরক্ষণ করবেন না, বিনামূল্যে খেলা কিনুন`,
  activity_rules_7: `7. কোনো বোনাস সঙ্গে মিশিয়ে দিতে পারবেন না`,
  activity_rules_8: `8. প্রতি জমা সর্বাধিক উত্তোলন হলো R$100,000.00`,
  event_instructions: 'ইভেন্ট নির্দেশিকা',
  agent_p1: 'আপনার অনন্য লিঙ্ক সম্প্রসারণ করুন অ্যাপ এবং সোশ্যাল মিডিয়ার মধ্যে',
  agent_p2:
    'ফেসবুক, ইনস্টাগ্রাম, টিকটক, টেলিগ্রাম, ওয়াটসঅ্যাপ, ইউটিউব, অন্যান্য, জল্পনা করুন এবং সবার পাশে আসুন, আপনার অনন্য লিঙ্কের মাধ্যমে নিবন্ধিত হওয়া সদস্যদের আপনি সরাসরি এজেন্ট হয়ে যান। তবে লক্ষ্য করুন যে আপনি আমন্ত্রিত করে আনুন নতুন সদস্যদের অবশ্যই সত্যক এবং বৈধ।',
  agent_step1:
    'আপনিরা যদি প্রথম জমাটি সফল হয়, তাহলে আপনি R$5 এর বোনাস জিততে পারেন। যেমন, যদি আপনি 10 জনকে প্রথম জমা দেওয়ার জন্য আমন্ত্রণ জানান, আপনি পারেন R$50 আয় করেন। আপনি আপনার ইতিহাস দেখতে এবং প্রমোশনের পাতায় নিবন্ধন করতে পারবেন যেখানে বোনাস এবং উচ্চ কমিশন প্রাপ্ত করতে পারবেন।',
  agent_step2:
    'আপনার অতিথি 1 বা তারও বেশি টাকা বেট করে, তাহলে আপনি ইতিমধ্যেই উচ্চ কমিশন প্রাপ্ত করতে পারেন! আপনি সারাজীবন সম্পর্কিত অবদানে উপভোগ করতে পারেন এবং একটি আমন্ত্রণ! সিস্টেম পূর্ববর্তী দিনের কমিশনটি সকাল 7টা পর পরিচালিত করবে।',
  agent_step2_1: 'কীভাবে পাওয়া যায়: লগইন - আমন্ত্রণ প্রমোশন - কমিশন সংগ্রহ',
  agent_table1: 'স্লট, ফিশিং এবং ব্লকচেনের জন্য বেটিং কমিশনের হারগুলি নিম্নলিখিতভাবে:',
  agent_table1_1: 'গ্রাহক টপ আপ 20 বেট 200',
  person: 'ব্যক্তি',
  agent_table2_title1: 'সদস্য বেটিং',
  agent_table2_title2: 'শুধুমাত্র স্লট গেম',
  agent_table2_example:
    'উদাহরণ: আপনার যদি 100 জন সদস্য থাকেন এবং দৈনিক মোট বেটের মূল্যটি 1 মিলিয়ন রিয়াল হয়, তাদের দিনের এই কমিশনটি হলো: 1,000,000 x 1.20% 12,000 রিয়াল। মাসিক আয় 100,000 অত্যন্ত সহজ অর্জন করার জন্য এটি।',
  reminder: 'মন্তব্য',
  agent_reminder:
    'একটি সদস্য অ্যাকাউন্ট যা একই আইপি, একই ডিভাইস, নাম, ব্যাঙ্ক কার্ড এবং অন্যান্য তথ্য সমতুল্য আপনাতে শুধুমাত্র একবার অংশগ্রহণ করতে পারে, এবং যে কেউ যে নিয়মগুলি লঙ্ঘন করে তাদের এই বোনাস থেকে লাভ নিতে পারবে না বা গ্রুপ প্রতিরোধ করা হবে এবং অ্যাকাউন্ট স্থায়ীভাবে ফ্রিজ হবে',
  agent_step3_1:
    'সদস্যদের অংশগ্রহণ সিস্টেম দ্বারা স্বয়ংক্রিয়ভাবে গণ্য হয়, যদি কোনও এভাবে আপত্তি থাকে তবে বেরি96 এর পরিদলিত অনুসন্ধানের ফলাফল প্রাধান্য থাকবে',
  agent_step3_2:
    'যদি আপনি মেম্বার অ্যাকাউন্ট / পাসওয়ার্ড ভুলে যান, তবে "24 ঘণ্টার অনলাইন কাস্টমার সার্ভিস" এ যোগাযোগ করে আপনার অ্যাকাউন্ট তথ্য পুনরুদ্ধার করার জন্য সাহায্য নিন।',
  agent_step3_3: 'বেরি96 এর অধিকার রয়েছে এই প্রচারণা যে সময়েই পরিবর্তন, বন্ধ বা বাতিল করতে পারে',
  agent_step3_4: 'পুরস্কার বোনাস গ্রহণ করতে পারবেন মুলত নগদ বা ওয়ালেটে ক্রেডিট হিসেবে',
  agent_step3_5: 'ফ্রি গেম সংরক্ষণ করবেন না, ফ্রি গেম কিনুন',
  agent_step3_6: 'কোনো বোনাস এবং ডিপোজিটের সাথে যুক্ত করা যাবে না',
  agent_step3_7: 'শুধুমাত্র স্লট গেমে পাওয়া যাবে',
  promo_concluded: 'প্রোমো শেষ হয়েছে! আগামীকাল আবার চেষ্টা করুন!',
  can_only_claim: '{{x}} থেকে {{y}} {{z}} সময়ের মধ্যে মাত্র বোনাস দাবি করতে পারবেন',
  please_login: 'বোনাস দাবি করতে আগে লগইন করুন!',
  back_to_home: 'হোমে ফিরে যান',
  bonus_claimed: 'বোনাস ইতিমধ্যে দাবি করা হয়েছে!',
  wallet: 'ওয়ালেট',
  bank_bsb: `ব্যাংক BSB`,
  pay_id: `পে আইডি`,
  from_selected_promotion: 'নির্বাচিত প্রমোশন থেকে',
  helpful_links: 'সাহায্যকারী লিঙ্ক',
  platform: 'প্ল্যাটফর্ম',
  community: 'সম্প্রদায়',
  support: 'সমর্থন',
  partnership_program: 'অংশগ্রহণ প্রোগ্রাম',
  help_center: 'সাহায্য কেন্দ্র',
  sports_policy: 'খেলাধুলার নীতি',
  aml_policy: 'AML নীতি',
  level: 'স্তর',
  back: 'পিছনে',
  network_error: 'নেটওয়ার্ক ত্রুটি',
  we_have_sent_otp: 'আমরা আপনার ফোন নম্বরে OTP প্রেরণ করেছি',
  your_otp_expire: 'আপনার OTP মেয়াদ উত্তীর্ণ হবে',
  didnt_receive: 'মেসেজ পেয়েননি?',
  send_again: 'আবার পাঠান',
  resend_again: 'আবার পাঠান ইন',
  invalid_verification: 'অবৈধ যাচাইকরণ কোড!',
  otp_expired: 'OTP মেয়াদ উত্তীর্ণ হয়েছে, অনুগ্রহ করে আবার ফোন নম্বর লিখুন এবং পুনরায় চেষ্টা করুন!',
  otp_expired_redirect: 'OTP মেয়াদ উত্তীর্ণ! লগইনে পুনর্নির্দেশনা দেওয়া হচ্ছে',
  captcha_check_failed: 'ক্যাপচা পরীক্ষা ব্যর্থ!',
  verify_phonenumber: 'ফোন নম্বর যাচাই করুন',
  send_otp: 'OTP পাঠান',
  success_verification: 'আপনার ফোন নম্বর যাচাই হয়েছে!',
  press_get_code: 'ভেরিফিকেশন কোড পেতে GET CODE বোতামটি চাপুন',
  otp_sent: 'যাচাইকরণ কোড প্রেরিত হয়েছে! চালিয়ে যান নীচের যাচাইকরণ কোড দিয়ে চালিয়ে যান',
  get_code: 'কোড পান',
  verify: 'যাচাই করুন',
  verification_code: 'যাচাইকরণ কোড',
  otp_not_found: 'OTP ফাংশন পাওয়া যায়নি!',
  terms_n_conditions: `শর্তাবলী ও শর্ত`,
  the_activity_x_in: `কার্যকলাপ {{x}} তে`,
  starts: `শুরু হয়`,
  ends: `শেষ হয়`,
  list_of_winners: `বিজয়ীদের তালিকা`,
  days: `দিন`,
  hours: `ঘন্টা`,
  minutes: `মিনিট`,
  seconds: `সেকেন্ড`,
  total_amount_received: `মোট গৃহীত পরিমাণ`,
  claim: `দাবি`,
  requirement_to_withdraw: `উত্তোলনের প্রয়োজনীয়তা:`,
  total_bet_x: `মোট বেট {{x}}`,
  x_of_y: `{{x}} এর {{y}}`,
  no_balance: `কোনো হিসাব নেই`,
  fullname_note: `উত্তোলনের সমস্ত সমস্যা হ্রাস করতে আপনার পূর্ণ নামটি আপনার ব্যাংক অ্যাকাউন্টের সাথে মিলে থাকতে নিশ্চিত হোন।`,
  number_exists: 'এই ফোন নম্বরটি ইতিমধ্যে নিবন্ধিত!',
  number_not_exists: 'এই ফোন নম্বরটি নিবন্ধিত নয়!',
  payment_draft: 'আপনি যদি নিশ্চিত হন',
  live_chat: 'লাইভ চ্যাট',
  please_download_x_to_play: 'খেলার জন্য দয়া করে {{x}} ডাউনলোড করুন।',
  password: 'পাসওয়ার্ড',
  play_now: 'এখনই খেলুন',
  vip_benefits: 'ভিআইপি সুবিধা',
  no_limits: 'কোন সীমা নেই',
  no_limits_description:
    'আমরা ভিআইপি ক্লাবের সকল খেলোয়াড়দের জন্য ব্যক্তিগত ভিআইপি পরিষেবা সহ অনন্য গেমিং শর্তাদি প্রদান করি। এখন আপনি চিন্তা ছাড়াই খেলতে পারেন! এটি খুবই সহজ - উত্তেজনার জন্য খেলুন, আপনার গেমিং দক্ষতা উন্নত করুন, আপনার সময় উপভোগ করুন এবং জেতার সুযোগগুলিতে কোনও সীমা নেই!',
  financial_privileges: 'আর্থিক সুবিধা',
  financial_privileges_description:
    'ভিআইপি ক্লাব উচ্চমানের ভিআইপি পরিষেবা পেতে ইচ্ছুক সকল খেলোয়াড়দের স্বাগত জানায়। আমাদের ওয়েবসাইটে আপনার জন্য উপলভ্য এক্সক্লুসিভ সুবিধা, বিশেষ প্রচার এবং সুবিধা! ভিআইপি খেলোয়াড়ের স্থিতি অত্যন্ত স্বল্প সময়ে তহবিল উত্তোলনের উচ্চ সীমা উন্মুক্ত করে! উপভোগ করুন!',
  personal_manager: 'ব্যক্তিগত ম্যানেজার',
  personal_manager_description:
    'আপনি যখন একজন ভিআইপি খেলোয়াড় হবেন তখন আপনি কোন সুবিধা পেতে চান? আপনার ব্যক্তিগত ম্যানেজারের সাথে আপনার চিন্তাগুলি ভাগ করুন এবং আমাদের জানান আপনি আমাদের ওয়েবসাইটে আরও কি দেখতে পছন্দ করবেন! আমরা সর্বদা দুর্দান্ত পরামর্শ গ্রহণের জন্য উন্মুক্ত!',
  vip_promotions: 'ভিআইপি প্রচার',
  vip_promotions_description:
    'আপনি কি আরও দুর্দান্ত প্রচার পেতে চান, ভিআইপি খেলোয়াড়রা এটির সমস্ত এবং আরও অনেক সুবিধা উপভোগ করে পুরষ্কার হিসাবে! তাদের একজন হন!',
  become_vip: 'ভিআইপি হন',
  play_the_way_you_want: 'আপনার ইচ্ছা অনুযায়ী খেলুন',
  join_vip_program: 'এখনই ভিআইপি প্রোগ্রামে যোগ দিন!',
  join_now: 'এখন যোগ দিন',
  vip_club: 'ভিআইপি ক্লাব',
  withdraw_limit: 'প্রত্যাহার সীমা',
  cashback: 'ক্যাশব্যাক',
  personal_account_manager: 'ব্যক্তিগত অ্যাকাউন্ট ম্যানেজার',
  dont_show_again: 'আবার দেখাবেন না',
  next: 'পরবর্তী',
  close: 'বন্ধ করুন',
};

export default bn;
