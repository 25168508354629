// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const my = {
  no: `မဟုတ်ဘူး`,
  select_country_code: `နိုင်ငံကုဒ်ကိုရွေးပါ`,
  balance: `လက်ကျန်ငွေ`,
  requirement: `လိုအပ်ချက်`,
  deposit_from_bank: `ဘဏကနေသွင်းငွေ`,
  game: `ဂိမ်း`,
  games: `ဂိမ်းများ`,
  sport: `အားကစား`,
  center: `ဗဟို`,
  mines: `မိုင်းများ`,
  crash: `ပျက်စီး`,
  lucky_colour: `ကံကောင်းသော အရောင်`,
  dice: `ချပ်`,
  limbo: `လင်ဘို`,
  lucky_gift: `ကံကောင်းသော လက်ဆောင်`,
  all_games: `အားလုံးသော ဂိမ်းများ`,
  game_history: `ဂိမ်းမှတ်တမ်း`,
  coupon_code: `ကူပွန်ကုဒ်`,
  telegram: `တယ်လီဂရမ်`,
  customer_service: `ဖောက်သည်ဝန်ဆောင်မှု`,
  player: `ကစားသူ`,
  winner: `အောင်သူ`,
  all_bets: `အားလုံးသော ဘလက်များ`,
  my_bets: `ကျွန်တော်၏ ဘလက်များ`,
  name: `အမည်`,
  time: `အချိန်`,
  bet_amount: `ဘလက်ငွေပမာဏ`,
  prediction: `ခန့်မှန်းချက်`,
  payout: `ငွေထုတ်`,
  login: `လော့ဂ်အင်`,
  register: `မှတ်ပုံတင်ပါ`,
  username_placeholder: `အသုံးပြုသူအမည်`,
  please_input_your_x: `သင့်{{x}}ကိုထည့်ပါ`,
  affiliate_referral: `ထပ်ဆင့် / ကိုယ်စားလှယ်အိုင်ဒီ (ဆိုင်ရာ)`,
  captcha: `ကပ်ချာ`,
  forgot_password: `စကားဝှက်မေ့လျော့`,
  minor_note: `ဤဆိုက်ကို သွားရန်၊ သင်သည် အသက် 18 နှစ်ကျော်ကြောင်းနှင့် သဘောတူကြောင်း သေချာစေပါ`,
  terms_of_service: `ဝန်ဆောင်မှုအချက်အလက်`,
  by_signing_in: 'လော့ဂ်အင် သို့မဟုတ် မှတ်ပုံတင်ခြင်းဖြင့် သင်သည်ကျွန်ုပ်တို့၏',
  dont_have_account: `အကောင့်မရှိဘူးလား?`,
  sign_up: `မှတ်ပုံတင်ပါ`,
  username: `အသုံးပြုသူအမည်`,
  email: `အီးမေးလ်`,
  full_name: `နာမည်အပြည့်`,
  confirm_18: `မှတ်ပုံတင်ခလုတ်ကိုနှိပ်ခြင်းဖြင့်၊ ကျွန်ုပ်သည် အသက် 18 နှစ်ကျော်ပြီး သင်၏`,
  already_have_account: `ပြီးသားအကောင့်ရှိပါသလား?`,
  share: `မျှဝေပါ`,
  deposit: `သွင်းငွေ`,
  promos: `ပရိုမိုးရှင်းများ`,
  bonus: `ဘောနပ်စ်`,
  app: `အက်ပ်`,
  load_more: `နောက်ထပ်တင်ပါ`,
  details: `အသေးစိတ်`,
  helps: `အကူအညီများ`,
  kyc_policy: `KYC မူဝါဒ`,
  privacy_policy: `ပုဂ္ဂလိကမူဝါဒ`,
  responsible_gaming: `တာဝန်ရှိသော ကစားခြင်း`,
  terms_and_condition: `စည်းမျဉ်းများနှင့် အခြေအနေများ`,
  faq: `FAQ`,
  about_us: `ကျွန်ုပ်တို့အကြောင်း`,
  amount: `ပမာဏ`,
  minimum: `အနည်းဆုံး`,
  maximum: `အများဆုံး`,
  enter_amount: `ပမာဏထည့်ပါ`,
  extra: `အပို`,
  deposit_bonus: `သွင်းငွေဘောနပ်စ်`,
  do_not_participate: `ပရိုမိုးရှင်းများတွင်မပါဝင်ပါ`,
  payment_problem: `ငွေပေးချေမှုပြဿနာ`,
  click_for_help: `အကူအညီအတွက်နှိပ်ပါ`,
  deposit_notes: `သွင်းငွေမှတ်ချက်များ`,
  available_balance: `အသုံးပြုနိုင်သော လက်ကျန်ငွေ`,
  withdraw: `ထုတ်ယူပါ`,
  withdrawal_notes: `ထုတ်ယူမှတ်ချက်များ`,
  withdrawal_note1: `လက်ရှိထုတ်ယူမှု အရေအတွက်`,
  withdrawal_note1_1: `vip`,
  withdrawal_note2: `ထုတ်ယူခ:`,
  min: `အနည်းဆုံး:`,
  max: `အများဆုံး:`,
  opening_times: `ဖွင့်ချိန်များ:`,
  withdrawal_note3: `1. အမြန်ဆုံးထုတ်ယူချိန် 10 မိနစ်အတွင်း`,
  withdrawal_note4: `2. ထုတ်ယူသည့်အချက်အလက်မှန်ကန်မှုကိုသေချာစေပါ။ ထုတ်ယူသည့်အချက်အလက်မှားယွင်းမှုဖြစ်ပါက သင့်ရဲ့ငွေများဆုံးရှုံးနိုင်ပါသည်။`,
  withdrawal_note5: `3. မေးခွန်းများရှိပါက ကျွန်ုပ်တို့ကို ဆက်သွယ်ရန် ကတိပြုပါ။`,
  notifications: `အကြောင်းကြားချက်များ`,
  invalid_x: `မှားသော {{x}}`,
  captcha_not_match: `ကပ်ချာ မကိုက်ညီပါ`,
  invalid_x_must_be_atleast_y_characters: `မှားသော {{x}}, အနည်းဆုံး {{y}} အက္ခရာများရှိရမည်`,
  x_is_required: `{{x}} လိုအပ်သည်`,
  phone_number: 'ဖုန်းနံပါတ်',
  phone_num_x_max: `ဖုန်းနံပါတ်အများဆုံး {{x}} လုံး`,
  email_sent: `အီးမေးလ် ပေးပို့ပြီး၊ အီးမေးလ်ကိုစစ်ဆေးပါ။`,
  enter_valid_email: `မှန်ကန်သော အီးမေးလ်ကို ထည့်ပါ။`,
  confirm: `အတည်ပြုပါ`,
  password_atleast_6_characters: `စကားဝှက်မှာ အနည်းဆုံး 6 အက္ခရာများ ရှိရမည်။`,
  password_reset_success: `စကားဝှက်ပြန်လည်သတ်မှတ်ခြင်း အောင်မြင်ပါသည်။`,
  new_password: `စကားဝှက် အသစ်`,
  bank: 'ဘဏ်',
  bank_transfer: `ဘဏ် လွှဲပြောင်းခြင်း`,
  bank_name: `ဘဏ်အမည်`,
  account_name: `အကောင့်အမည်`,
  account_number: `အကောင့်နံပါတ်`,
  pix_number: `PIX နံပါတ်`,
  drop_or_select: `ဖိုင်ကို ကျွတ်ချပါ သို့မဟုတ် ရွေးပါ`,
  drop_files_here: `ဖိုင်များကို ဤနေရာတွင် ကျွတ်ချပါ`,
  transfer_to: `မှ လွှဲပြောင်းပါ`,
  upload_receipt: `ငွေလွှဲထုတ်လက်ခံဖြတ်ပိုင်းကို တင်ပါ`,
  select_bonus: `ဘောနပ်စ် ရွေးပါ`,
  no_promotion: `ပရိုမိုးရှင်းမရှိပါ`,
  please_upload_receipt: `ငွေလွှဲထုတ်လက်ခံဖြတ်ပိုင်းကို တင်ပါ`,
  bank_account: `ဘဏ်အကောင့်`,
  add_account: `အကောင့် ထည့်ပါ`,
  account_holder_name: `အကောင့် ထိန်းသိမ်းသူ အမည်`,
  name_of_the_account_holder: `အကောင့် ထိန်းသိမ်းသူ၏ အမည်`,
  fill_in_bank_account: `ဘဏ်အကောင့်ကို ဖြည့်ပါ`,
  withdraw_information: `ထုတ်ယူမှု အချက်အလက်`,
  note: `မှတ်ချက်`,
  amount_withdrawn: `ထုတ်ယူငွေပမာဏ`,
  amount_no_exceed_balance: `ထုတ်ယူငွေပမာဏသည် အသုံးပြုနိုင်သော လက်ကျန်ငွေမကျော်လွန်ရပါ`,
  minimum_withdraw_amount_is_x: `အနည်းဆုံးထုတ်ယူငွေပမာဏမှာ {{x}} ဖြစ်သည်`,
  transaction_history: `ငွေလွှဲမှတ်တမ်း`,
  date_from: `စတင်သည့် ရက်`,
  date_to: `ဆုံးသည့် ရက်`,
  account_name_must_be_4: `အကောင့်အမည်သည် အရေအတွက် 4 အထက်ရှိရမည်`,
  account_number_must_be_6: `အကောင့်နံပါတ်သည် အရေအတွက် 6 အထက်ရှိရမည်`,
  please_fill_in_your_account_name: `သင်၏ အကောင့်အမည်ကို ဖြည့်ပါ`,
  please_fill_in_your_account_number: `သင်၏ အကောင့်နံပါတ်ကို ဖြည့်ပါ`,
  please_fill_in_your_pix_number: `သင်၏ PIX နံပါတ်ကို ဖြည့်ပါ`,
  submit: `တင်ပြပါ`,
  add_new_account: `အကောင့် အသစ် ထည့်ပါ`,
  save_changes: `ပြုပြင်မှုများ သိမ်းပါ`,
  something_went_wrong: `တစ်စုံတစ်ခုမှားယွင်းသည်`,
  my_tickets: `ကျွန်ုပ်၏ လက်မှတ်များ`,
  tickets_history: `လက်မှတ်မှတ်တမ်း`,
  create_new_ticket: `လက်မှတ်အသစ် ဖန်တီးပါ`,
  reference_id: `ကိုးကားမှု အိုင်ဒီ`,
  select_category: `အမျိုးအစားရွေးပါ`,
  select_priority: `ဦးစားပေးမှု ရွေးပါ`,
  category: `အမျိုးအစား`,
  subject: `ခေါင်းစဉ်`,
  description: `ဖော်ပြချက်`,
  priority: `ဦးစားပေးမှု`,
  status: `အခြေအနေ`,
  last_update: `နောက်ဆုံး အပ်ဒိတ်`,
  open: `ဖွင့်ထားသည်`,
  closed: `ပိတ်ထားသည်`,
  ticket_opened_successfully: `လက်မှတ်ကို အောင်မြင်စွာ ဖွင့်သည်`,
  tickets: `လက်မှတ်များ`,
  close_ticket: `လက်မှတ်ကို ပိတ်သည်`,
  play: `ကစားပါ`,
  my_wallet: `ကျွန်ုပ်၏ပိုက်ဆံအိတ်`,
  rules: `စည်းမျဉ်းများ`,
  view_more: `နောက်ထပ်ကြည့်ပါ`,
  disclaimer: `ဖြတ်ကြောင်းဖွင့်ဟခြင်း`,
  best_casino_game: `အကောင်းဆုံး ကာစီနိုဂိမ်း`,
  about_the_casino: `ကာစီနိုအကြောင်း`,
  my_favorites: `ကျွန်ုပ်အကြိုက်များ`,
  read_more: `နောက်ထပ် ဖတ်ပါ`,
  closed_ticket: `ပိတ်ထားသော လက်မှတ်`,
  bet_now: `ယခု ဘလက်သည်`,
  win: `အနိုင်ရသည်`,
  lose: `ရှုံးသည်`,
  home: `ပင်မစာမျက်နှာ`,
  menu: `မီနူး`,
  featured_game: `အထူးပြု လက်တွေ့ပြသမှု အကြောင်း`,
  game_details: `ဂိမ်းအသေးစိတ်`,
  loading: `တင်ဆောင်နေသည်`,
  gaming: `ကစားနေသည်`,
  live_casino: `တိုက်ရိုက် ကာစီနို`,
  poker: `ပိုကာ`,
  sports_betting: `အားကစား ဘလက်စင်`,
  transactions: `ငွေစာရင်းများ`,
  select_image: `ပုံရွေးပါ`,
  upload: `တင်ပါ`,
  change_language: `ဘာသာစကားပြောင်းရန်`,
  system_error: `စနစ် အမှား`,
  no_transactions_found: `ငွေစာရင်း မတွေ့ပါ`,
  edit: `တည်းဖြတ်ပါ`,
  save: `သိမ်းဆည်းပါ`,
  delete: `ဖျက်ပစ်ပါ`,
  clear: `ရှင်းလင်းပါ`,
  filter: `စစ်ထုတ်ခြင်း`,
  search: `ရှာဖွေပါ`,
  confirm_delete: `ဖျက်ပစ်မှုကို အတည်ပြုပါ`,
  confirmation: `အတည်ပြုချက်`,
  are_you_sure: `သေချာပါသလား`,
  yes: `ဟုတ်ကဲ့`,
  sign_out: `အကောင့်မှ ထွက်သည်`,
  welcome_back: `ပြန်လည်ကြိုဆိုပါသည်`,
  please_sign_in: `ကျေးဇူးပြု၍ လော့ဂ်အင်လုပ်ပါ`,
  bet_history: `ဘလက်မှတ်တမ်း`,
  play_now: `ယခု ကစားပါ`,
  daily_promotion: `နေ့စဉ် ပရိုမိုးရှင်း`,
  loading_more_games: `ပိုမိုသောဂိမ်းများကို တင်ဆောင်နေသည်`,
  new_game: `ဂိမ်းအသစ်`,
  popular_games: `လူကြိုက်များသော ဂိမ်းများ`,
  trending_games: `လူကြိုက်များသော ဂိမ်းများ`,
  no_games_found: `ဂိမ်းမတွေ့ပါ`,
  error_loading_games: `ဂိမ်းများတင်နေစဉ် အမှားဖြစ်သည်`,
  explore_more_games: `နောက်ထပ် ဂိမ်းများကို ရှာဖွေပါ`,
  games_by_category: `အမျိုးအစားဖြင့် ဂိမ်းများ`,
  jackpot_games: `ဂျက်ပါ့ဂိမ်းများ`,
  upcoming_games: `လာမည့် ဂိမ်းများ`,
  top_rated_games: `အမြင့်ဆုံး ဂိမ်းများ`,
  recently_played_games: `မကြာသေးမီက ကစားခဲ့သော ဂိမ်းများ`,
  clear_filters: `စစ်ထုတ်ချက်များ ရှင်းလင်းပါ`,
  apply_filters: `စစ်ထုတ်ချက်များ အသုံးပြုပါ`,
  most_played_games: `အများဆုံး ကစားသော ဂိမ်းများ`,
  play_for_fun: `ပျော်ရွှင်စွာ ကစားပါ`,
  new_releases: `အသစ်ထွက်သော ဂိမ်းများ`,
  casino_games: `ကာစီနိုဂိမ်းများ`,
  featured_games: `အထူးပြု လက်တွေ့ပြသမှု ဂိမ်းများ`,
  reload_bonus: `ဘောနပ်စ် ပြန်တင်ပါ`,
  referral_bonus: `သူငယ်ချင်းကြား ဘောနပ်စ်`,
  exclusive_offers: `အထူး ကမ်းလှမ်းချက်များ`,
  top_players: `အကောင်းဆုံး ကစားသမားများ`,
  leaderboard: `ဦးဆောင်ဘုတ်`,
  recent_winners: `မကြာသေးမီက အောင်သူများ`,
  prize_pool: `ဆုငွေကန်`,
  last_winner: `နောက်ဆုံးအောင်သူ`,
  play_responsibly: `တာဝန်ရှိစွာ ကစားပါ`,
  terms_conditions: `စည်းမျဉ်းများနှင့် အခြေအနေများ`,
  contact_us: `ကျွန်ုပ်တို့ကို ဆက်သွယ်ပါ`,
  support_center: `အကူအညီဗဟို`,
  language_settings: `ဘာသာစကား သတ်မှတ်ချက်များ`,
  feedback: `တုံ့ပြန်ချက်`,
  report_issue: `ပြဿနာ အစီရင်ခံသည်`,
  settings: `ဆက်တင်များ`,
  account_settings: `အကောင့် သတ်မှတ်ချက်များ`,
  payment_methods: `ငွေပေးချေမှု နည်းလမ်းများ`,
  security: `လုံခြုံရေး`,
  verification: `အတည်ပြုခြင်း`,
  game_providers: `ဂိမ်း ပံ့ပိုးသူများ`,
  withdraw_successful: `အောင်မြင်စွာ ထုတ်ယူသည်`,
  deposit_successful: `အောင်မြင်စွာ သွင်းငွေသည်`,
  insufficient_balance: `လက်ကျန်ငွေ မလုံလောက်ပါ`,
  check_balance: `လက်ကျန်ငွေ စစ်ဆေးပါ`,
  view_transactions: `ငွေစာရင်းများ ကြည့်ပါ`,
  transfer_funds: `ငွေ ပြောင်းလဲသည်`,
  bonuses: `ဘောနပ်စ်များ`,
  rewards: `ဆုများ`,
  promotions: `ပရိုမိုးရှင်းများ`,
  my_profile: `ကျွန်ုပ်၏ ကိုယ်ရေးအချက်အလက်`,
  personal_info: `ပုဂ္ဂိုလ်ရေး အချက်အလက်`,
  address: `လိပ်စာ`,
  phone: `ဖုန်း`,
  preferences: `ကွက်ချက်များ`,
  language: `ဘာသာစကား`,
  currency: `ငွေကြေး`,
  security_settings: `လုံခြုံရေး သတ်မှတ်ချက်များ`,
  change_password: `စကားဝှက်ပြောင်းသည်`,
  two_factor_authentication: `နှစ်ဆ ကာကွယ်ရေး`,
  login_activity: `လော့ဂ်အင် လှုပ်ရှားမှု`,
  notification_settings: `အကြောင်းကြားချက် သတ်မှတ်ချက်များ`,
  email_notifications: `အီးမေးလ် အကြောင်းကြားချက်များ`,
  sms_notifications: `SMS အကြောင်းကြားချက်များ`,
  push_notifications: `ပုရောဂျက် အကြောင်းကြားချက်များ`,
  marketing_preferences: `မားကတင်း သတ်မှတ်ချက်များ`,
  save_preferences: `သတ်မှတ်ချက်များ သိမ်းဆည်းပါ`,
  support: `အထောက်အပံ့`,
  help_center: `အကူအညီ ဗဟို`,
  live_chat: `တိုက်ရိုက် စကားပြော`,
  community: `အသိုင်းအဝိုင်း`,
  submit_a_ticket: `တစ်ခုထဲ လက်မှတ်တင်ပါ`,
  track_tickets: `လက်မှတ်များ ခြေရာခံပါ`,
  recent_activity: `မကြာသေးမီက လှုပ်ရှားမှု`,
  logout: `လော့ဂ်အထွက်`,
  choose_date: `ရက်ရွေးချယ်ပါ`,
  select_both_date: `ရက်နှစ်ခုလုံးကိုရွေးပါ`,
  from: `မှ`,
  to: `သို့`,
  apply: `လျှောက်ထားပါ`,
  learn_more: `ပိုမိုသိရှိရန်`,
  password_doesnt_match: `စကားဝှက်မကိုက်ညီပါ`,
  cancel: `ပယ်ဖျက်ပါ`,
  confirm_to_pay: `ငွေပေးချေမှုအတည်ပြုပါ`,
  deposit_amount: `သွင်းငွေပမာဏ`,
  loading_please_wait: `တင်နေသည်၊ ကျေးဇူးပြု၍စောင့်ပါ`,
  account: `အကောင့်`,
  profile: `ပရိုဖိုင်`,
  bank_list: `ဘဏ်စာရင်း`,
  in_maintenance: `ပြုပြင်ထိန်းသိမ်းမှုအတွက်`,
  birthday: `မွေးနေ့`,
  birthday_bonus: `ဘောနပ်စ်အတွက်မိမိ၏မွေးနေ့ကိုထည့်ပါ! 🎉🎁`,
  referral_code: `ဖိတ်ကြားရေးကုဒ်`,
  referral: `ဖိတ်ကြားချက်`,
  my_referral: `ကျွန်ုပ်၏ဖိတ်ကြားချက်`,
  my_claim: `ကျွန်ုပ်၏ပိုင်ခွင့်`,
  claim_profit: `အမြတ်ငွေတောင်းဆိုမည်`,
  available_profit: `ရရှိနိုင်သောအမြတ်`,
  claimed_profit: `တောင်းခံထားသောအမြတ်`,
  all_time_profit: `စဉ်ဆက်မပြတ်အမြတ်`,
  referral_note_on_x: `မိတ်ဆွေများသည် {{x}} တွင်ကစားချိန်တိုင်းအမြတ်ရရှိပါသည်`,
  tier: `အဆင့်`,
  commission: `ကော်မရှင်`,
  referred_member: `ဖိတ်ကြားထားသောအဖွဲ့ဝင်`,
  profit_earned: `ရရှိသောအမြတ်`,
  account_information: `အကောင့်အချက်အလက်`,
  invite_friends: `မိတ်ဆွေများဖိတ်ပါ`,
  how_it_work: `ဒါဘယ်လိုအလုပ်လုပ်သလဲ`,
  invite_note: `သင့်ဖိတ်ကြားရေးကုဒ်ဖြင့်သင့်မိတ်ဆွေမှစာရင်းသွင်းပါကအထူးဖိတ်ကြားရေးဘောနပ်စ်ရယူပါ!`,
  share_via: `မှတဆင့်မျှဝေပါ`,
  referral_link: `ဖိတ်ကြားရေးလင့်ခ်`,
  total_profit: `စုစုပေါင်းအမြတ်`,
  copied: `ကူးယူပြီး`,
  nothing_to_claim: `တောင်းဆိုရန်မရှိပါ`,
  claim_history: `တောင်းဆိုမှုမှတ်တမ်း`,
  downline: `ကျွန်ုပ်၏အောက်ပါများ`,
  last_x_digit_bank_ref_number: `ဘဏ်ရေဖျောက်နံပါတ်၏နောက်ဆုံး {{x}} လုံး`,
  bank_ref_number_validation_x: `ဘဏ်ရေဖျောက်နံပါတ်သည်လိုအပ်ပြီး {{x}} အတွင်းဖြစ်ရပါမည်`,
  sort: 'စီမံ',
  login_directly: `တိုက်ရိုက်လော့ဂ်အင်ဝင်ပါ`,
  our_game_provides: `ကျွန်ုပ်တို့၏ဂိမ်းပံ့ပိုးသူများ`,
  we_accept_below_bank: `ကျွန်ုပ်တို့သည်အောက်ပါဘဏ်များကိုလက်ခံပါသည်`,
  we_accept_payment_methods: `ကျွန်ုပ်တို့သည်ငွေပေးချေမှုနည်းလမ်းများကိုလက်ခံပါသည်`,
  refer_and_reward: `ဖိတ်ကြားနှင့်ဆုလာဘ်`,
  linked_successfully_x: `{{x}} ဖြင့်အောင်မြင်စွာချိတ်ဆက်ပြီး! ပထမဆုံးအကောင့်စာရင်းသွင်းပါ`,
  more_x_of_y: `{{x}}/{{y}} ကျန်သေးသည်`,
  default: `ပုံမှန်`,
  promotion: `မြှင့်တင်မှု`,
  daily_mission: 'နေ့စဉ်မစ်ရှင်',
  agent: 'ကိုယ်စားလှယ်',
  download: 'ဒေါင်းလုဒ်',
  cash_claim: 'ငွေတောင်းဆိုမှု',
  demo: 'အစမ်း',
  favorites: 'အကြိုက်များ',
  popular: 'လူကြိုက်များ',
  claim_bonus: `ဘောနပ်စ်တောင်းဆိုပါ`,
  currently_displaying: 'လက်ရှိပြသနေသည် {{z}} ဂိမ်းထဲမှ {{x}} {{y}} ဂိမ်း',
  all_x: 'အားလုံး {{x}}',
  no_results: 'ရလဒ်မရှိပါ',
  search_games: 'ဂိမ်းများရှာဖွေပါ',
  please_scan_using_your: `သင့်မှတဆင့်စစ်ဆေးပါ`,
  vip_level: `ဗွီအိုင်ပီအဆင့်`,
  bonus_direct: `ဘောနပ်စ်တိုက်ရိုက်`,
  rebate_level: `ပြန်အမ်းငွေအဆင့်`,
  vip_header: `Slot Machines, Board and Card Games, Fishing, Videos, Sports and Lottery Games မှာ Berry96 တွင်တင်ထားသောအားလုံးအရစ်ကျအမြဲတမ်းစုဆောင်းသည်။ တရားဝင်ကစားခြင်း = အမြဲတမ်းဝင်ငွေ နှင့်ငွေကျော်ကြားမှု!`,
  vip_example: `
  ဥပမာ 1: အဖွဲ့ဝင်များသည်စုစုပေါင်းသွင်းငွေ 1 သန်းအထိရောက်လျှင် VIP အဆင့် 2 ရောက်ပြီး R$210 ဘောနပ်စ်နှင့်ပြန်အမ်းငွေ 3.50% ရရှိသည်။ စုစုပေါင်းသွင်းငွေ 6 သန်းအထိရောက်လျှင် VIP အဆင့် 3 ရောက်ပြီး R$800 ဘောနပ်စ်နှင့်ပြန်အမ်းငွေ 4% ရရှိသည်`,
  activity_rules: `လှုပ်ရှားမှုစည်းမျဉ်းများ:`,
  activity_rules_1: `1. ဘောနပ်စ်အားလုံးကိုငွေထုတ်ယူရန်အတွက် x3 Rollover ရှိရန်လိုအပ်သည်`,
  activity_rules_2: `2. ပြန်အမ်းငွေဘောနပ်စ်ကိုသတ်မှတ်ထားသောအချိန်အတွင်းတောင်းဆိုပါ။ နောက်ကျပါက စွန့်လွှတ်ထားသည်ဟုယူဆမည်`,
  activity_rules_3: `3. အဖွဲ့ဝင်များ၏လှုပ်ရှားမှုများသည်စနစ်မှအလိုအလျောက်ရေတွက်ထားသည်။ ပြဿနာရှိပါက Berry96 ၏စစ်တမ်းရလဒ်ကိုသဘောတူသည်`,
  activity_rules_4: `4. သင့်အကောင့်/စကားဝှက်မေ့သွားပါက "24 နာရီအွန်လိုင်းဖောက်သည်ဝန်ဆောင်မှု" နှင့်ဆက်သွယ်၍ အကောင့်အချက်အလက်ပြန်လည်ရယူပါ`,
  activity_rules_5: `5. Berry96 သည်ဤမြှင့်တင်မှုကိုအချိန်မရွေးပြောင်းလဲ၊ ရပ်ဆိုင်း၊ ပယ်ဖျက်ခွင့်ရှိသည်`,
  activity_rules_6: `6. အခမဲ့ဂိမ်းကိုသိမ်းဆည်းပါမူ၊ အခမဲ့ဂိမ်းကိုဝယ်ပါ`,
  activity_rules_7: `7. ဘောနပ်စ်နှင့်ပေါင်းစပ်၍မရ`,
  activity_rules_8: `8. တစ်ကြိမ်သွင်းငွေရသွင်းငွေအများဆုံးမှာ R$100.000.00`,
  event_instructions: 'ပွဲစဉ်လမ်းညွှန်ချက်များ',
  agent_p1: 'သင့်ထူးခြားသောလင့်ခ်ကိုအက်ပ်များနှင့်လူမှုမီဒီယာများတွင်မျှဝေပါ',
  agent_p2:
    'Facebook, Instagram, Tiktok, Telegram, WhatsApp, YouTube တို့အပါအဝင်၊ အမြန်ဆုံးဖိတ်ကြားပါ၊ သင့်ထူးခြားသောလင့်ခ်မှတစ်ဆင့်စာရင်းသွင်းသောအသစ်အဖွဲ့ဝင်များသည်သင့်တိုက်ရိုက်ကိုယ်စားလှယ်ဖြစ်သည်။ သို့သော် သတိပြုပါ၊ သင့်ဖိတ်ကြားသောအသစ်အဖွဲ့ဝင်များသည်တရားဝင်ဖြစ်ရပါမည်',
  agent_step1:
    'မိတ်ဆွေဖိတ်ကြားမှုရလဒ်အောင်မြင်သည်ဆိုပါက R$5.00 ဘောနပ်စ်ရရှိနိုင်သည်။ ဥပမာ-သင် 10 ဦးဖိတ်ပါက R$50.00 ရရှိနိုင်သည်။ ပိုများသောသူများကိုဖိတ်သည်ဆိုပါကပိုများသောဘောနပ်စ်ရရှိနိုင်သည်။ သင်၏အထူးပေးပို့နှင့်အဆင့်မြှင့်ထားသောမှတ်တမ်းကိုစာရင်းစစ်ပါ',
  agent_step2:
    'သင့်ဖိတ်ကြားသူများသည် R$1 သို့မဟုတ်ပို၍တရားဝင်ရိုက်ကူးပါကအမြဲတမ်းအမြတ်အစွန်းများကိုခံစားနိုင်သည်။ အမြတ်အစွန်းအလွန်မြင့်မားသောကော်မရှင်ရရှိနိုင်သည်။ စနစ်သည်နောက်နေ့နံနက် 7 နာရီတွင်အင်အားစုစုပေါင်း',
  agent_step2_1: 'ရယူရန်-အကောင့်ဝင်မည်-ဖိတ်ကြားရေးမြှင့်တင်မှု-ကော်မရှင်စုစည်းပါ',
  agent_table1: 'Slot, Fishing နှင့် Blockchain အတွက်သတ်မှတ်ထားသောကော်မရှင်အနှုန်း',
  agent_table1_1: 'ဖောက်သည် 20 ထပ်တိုးရက် 200 ထိုးခြင်း',
  person: 'လူ',
  agent_table2_title1: 'အဖွဲ့ဝင်ရိုက်ကူးခြင်း',
  agent_table2_title2: 'Slot ဂိမ်းများသာ',
  agent_table2_example:
    'ဥပမာ-သင် 100 ဦးရှိပြီးအမှန်တကယ်တန်ဖိုးရှိသောရက်မှတ်တမ်းသည်နေ့စဉ် 1 သန်းအထိဖြစ်သည်။ သင့်အားနေ့ရက်ရက်ချင်းကော်မရှင်နှုန်းကို 1,000,000 x 1.20% 12,000 ဖြစ်သည်။ လစဉ်ဝင်ငွေ 100,000 ထိလွယ်ကူသောပမာဏ',
  reminder: 'သတိပေးချက်',
  agent_reminder:
    'အနည်းဆုံး IP, ထပ်တူကိရိယာ, အမည်, ဘဏ်ကဒ် နှင့်အခြားအချက်အလက်မှတစ်ဆင့်သတ်မှတ်ထားသောအကောင့်သည်တစ်ကြိမ်သာပါဝင်ပါသည်၊ စည်းမျဉ်းကိုဖောက်ဖျက်ပါကဤဘောနပ်စ်မရနိုင်ပါဘူး',
  agent_step3_1: 'အဖွဲ့ဝင်များ၏လုပ်ဆောင်မှုကိုစနစ်မှအလိုအလျောက်ရေတွက်သည်၊ ပြဿနာရှိပါက Berry96 ၏စစ်တမ်းရလဒ်ကိုသဘောတူသည်',
  agent_step3_2:
    'သင့်အကောင့်/စကားဝှက်မေ့သွားပါကကျွန်ုပ်တို့၏ 24 နာရီဖောက်သည်ဝန်ဆောင်မှုနှင့်ဆက်သွယ်၍ အကောင့်အချက်အလက်ပြန်လည်ရယူပါ',
  agent_step3_3: 'Berry96 သည်ဤမြှင့်တင်မှုကိုအချိန်မရွေးပြောင်းလဲ, ရပ်ဆိုင်း, ပယ်ဖျက်ခွင့်ရှိသည်',
  agent_step3_4: 'ကိုယ်စားလှယ်သည် ကော်မရှင်ဘောနပ်စ်ကိုငွေသို့မဟုတ်ပိုက်ဆံအိတ်အကြွေးအဖြစ်ရရှိနိုင်သည်',
  agent_step3_5: 'အခမဲ့ဂိမ်းမသိမ်းဆည်းပါနဲ့, အခမဲ့ဂိမ်းဝယ်ပါ',
  agent_step3_6: 'ဘောနပ်စ်နှင့်အပ်ငွေကိုပေါင်းစပ်၍မရ',
  agent_step3_7: 'Slot ဂိမ်းများအတွက်သာရရှိနိုင်သည်',
  promo_concluded: 'မြှင့်တင်မှုပြီးဆုံးပါပြီ! မနက်ဖြန်ပြန်စစ်ပါ!',
  can_only_claim: 'ဘောနပ်စ်ကို {{x}} မှ {{y}} {{z}} အချိန်အတွင်းသာတောင်းဆိုနိုင်သည်',
  please_login: 'ဘောနပ်စ်တောင်းဆိုရန်အရင်ဆုံးအကောင့်ဝင်ပါ!',
  back_to_home: 'မူလနေရာသို့ပြန်သွားပါ',
  bonus_claimed: 'ဘောနပ်စ်တောင်းဆိုပြီး!',
  wallet: 'ပိုက်ဆံအိတ်',
  bank_bsb: `ဘဏ် BSB`,
  pay_id: `PayID`,
  from_selected_promotion: 'ရွေးချယ်ထားသောမြှင့်တင်မှုမှ',
  helpful_links: 'အကျိုးပြုလင့်ခ်များ',
  platform: 'ပလက်ဖောင်း',
  partnership_program: 'မဟာဖွဲ့စည်းရေးအစီအစဉ်',
  sports_policy: 'အားကစားမူဝါဒ',
  aml_policy: 'AML မူဝါဒ',
  level: 'အဆင့်',
  back: 'နောက်သို့',
  network_error: 'ကွန်ယက်အမှား',
  we_have_sent_otp: 'OTP ကိုသင့်ဖုန်းနံပါတ်သို့ပို့လိုက်ပါပြီ',
  your_otp_expire: 'သင့် OTP သည် သက်တမ်းကုန်ဆုံးမည်',
  didnt_receive: 'မက်ဆေ့စ်မရခဲ့ပါလား?',
  send_again: 'ပြန်ပို့ပါ',
  resend_again: 'ပြန်ပို့ပါ {{x}} အတွင်း',
  invalid_verification: 'စစ်ဆေးသည့်ကုဒ်မမှန်ကန်ပါ!',
  otp_expired: 'OTP သက်တမ်းကုန်သွားသည်၊ ကျေးဇူးပြု၍သင့်ဖုန်းနံပါတ်ထပ်မံရိုက်ထည့်ပါ',
  otp_expired_redirect: 'OTP သက်တမ်းကုန်သည်! အကောင့်ဝင်ရန်ပြန်သွားနေသည်',
  captcha_check_failed: 'Captcha စစ်ဆေးမှုမအောင်မြင်ပါ!',
  verify_phonenumber: 'ဖုန်းနံပါတ်စစ်ဆေးပါ',
  send_otp: 'OTP ပို့ပါ',
  success_verification: 'သင့်ဖုန်းနံပါတ်ကိုအောင်မြင်စွာစစ်ဆေးပြီးပါပြီ!',
  press_get_code: 'SMS မှတဆင့်စစ်ဆေးရန် GET CODE ခလုတ်ကိုနှိပ်ပါ',
  otp_sent: 'စစ်ဆေးသည့်ကုဒ်ပို့ပြီး! ဆက်လက်လုပ်ဆောင်ရန်အောက်တွင်စစ်ဆေးသည့်ကုဒ်ကိုရိုက်ထည့်ပါ',
  get_code: 'ကုဒ်ရယူပါ',
  verify: 'စစ်ဆေးပါ',
  verification_code: 'စစ်ဆေးသည့်ကုဒ်',
  otp_not_found: 'OTP လုပ်ဆောင်မှုမတွေ့ပါ!',
  terms_n_conditions: `စည်းကမ်းနှင့်သတ်မှတ်ချက်`,
  the_activity_x_in: `လှုပ်ရှားမှု {{x}} မှာ`,
  starts: `စတင်`,
  ends: `အဆုံး`,
  list_of_winners: `အောင်မြင်သူများစာရင်း`,
  days: `ရက်များ`,
  hours: `နာရီများ`,
  minutes: `မိနစ်များ`,
  seconds: `စက္ကန့်များ`,
  total_amount_received: `စုစုပေါင်းရရှိသောပမာဏ`,
  claim: `တောင်းဆိုမည်`,
  requirement_to_withdraw: `ငွေထုတ်ယူရန်လိုအပ်ချက်`,
  total_bet_x: `စုစုပေါင်း {{x}} ကစား`,
  x_of_y: `{{x}} ၏ {{y}}`,
  no_balance: `လက်ကျန်ငွေမရှိပါ`,
  fullname_note: `သင့်နာမည်နှင့်ဘဏ်အကောင့်ကိုက်ညီမှုရှိရန်သေချာပါကငွေထုတ်ယူမှုပြဿနာမဖြစ်ရ`,
  number_exists: 'ဤဖုန်းနံပါတ်သည်လက်ရှိရှိပြီးဖြစ်သည်!',
  number_not_exists: 'ဤဖုန်းနံပါတ်သည်မရှိပါ!',
  payment_draft: 'ငွေပေးချေမှုကိုအတည်ပြုထားပါက ငွေပေးချေမှုအဆင့်သွားနေသေးသည်၊ ကျေးဇူးပြု၍စောင့်ပါ',
  slots: 'ဆလော့',
  card_game: 'ကတ်ဂိမ်း',
  casino: 'ကာစီနို',
  fishing: 'ငါးဖမ်း',
  sports: 'အားကစား',
  please_download_x_to_play: 'ကစားရန် {{x}} ကိုဒေါင်းလုဒ်လုပ်ပါ။',
  password: 'စကားဝှက်',
  vip_benefits: 'VIP အကျိုးကျေးဇူးများ',
  no_limits: 'ကန့်သတ်ချက်မရှိ',
  no_limits_description:
    'ကျွန်ုပ်တို့သည် VIP ကလပ်ရှိ အားလုံးသော ကစားသမားများအတွက် ထူးခြားသော ကစားစည်းမျဉ်းများနှင့်ပတ်သက်၍ ပုဂ္ဂိုလ်ရေး VIP ၀န်ဆောင်မှုများ ပေးလျက်ရှိပါသည်။ ယခု ကစားရန် စိတ်ပူရန်မလိုတော့ပါ။ လွယ်ကူစွာ - စိတ်လှုပ်ရှားစွာ ကစားပါ၊ သင်၏ ကစားနည်းကျွမ်းကျင်မှုများ တိုးတက်အောင်လုပ်ပါ၊ သင်၏ အချိန်ကို ပျော်မွေ့စွာဖြတ်သန်းပြီး အနိုင်ရခြင်းတွင် မည်သည့် ကန့်သတ်ချက်မှမရှိဘဲ ကျော်လွှားနိုင်ပါသည်!',
  financial_privileges: 'ငွေကြေးဆိုင်ရာ အကျိုးကျေးဇူးများ',
  financial_privileges_description:
    'VIP ကလပ်သည် မြင့်မားသောအရည်အသွေးရှိ VIP ၀န်ဆောင်မှုများကို လိုလားသော ကစားသမားများအားလုံးကို ကြိုဆိုပါသည်။ ကျွန်ုပ်တို့၏ ဝက်ဘ်ဆိုက်တွင် သင့်အတွက် သီးသန့် အကျိုးကျေးဇူးများ၊ အထူးပရိုမိုးရှင်းများနှင့် အကျိုးကျေးဇူးများ ရရှိနိုင်ပါသည်။ VIP ကစားသမား၏ အနေအထားသည် စံချိန်ပြိုင်တိုင်းထွာ အချိန်မီတွင် မြင့်မားသောငွေထုတ်ယူမှု ခွင့်ပြုချက်များ ဖွင့်ပါသည်! ရှုစားလိုက်ပါ!',
  personal_manager: 'ပုဂ္ဂိုလ်ရေး မန်နေဂျာ',
  personal_manager_description:
    'သင်သည် VIP ကစားသမားဖြစ်လာသောအခါ သင် မည်သည့် အကျိုးကျေးဇူးကို ရရှိလိုပါသလဲ? သင့်၏ ပုဂ္ဂိုလ်ရေး မန်နေဂျာနှင့် သင်၏ အတွေးများကို မျှဝေပြီး ကျွန်ုပ်တို့၏ ဝက်ဘ်ဆိုက်တွင် အခြား ဘာများကို ရှုစားလိုသည်ကို ပြောပြပါ! ကျွန်ုပ်တို့သည် အမြဲကောင်းသော အကြံဥာဏ်များကို လက်ခံသည့်အနေဖြင့် ပွင့်လင်းလျက်ရှိပါသည်!',
  vip_promotions: 'VIP ပရိုမိုးရှင်းများ',
  vip_promotions_description:
    'သင်သည် ပိုမိုကောင်းမွန်သော ပရိုမိုးရှင်းများကို ရရှိလိုပါသလား၊ VIP ကစားသမားများသည် အားလုံးကို ရရှိပြီး အကျိုးအမြတ်များကို အခွင့်အရေးအဖြစ် ရရှိသည်! သူတို့ထဲက တစ်ယောက် ဖြစ်လာလိုက်ပါ!',
  become_vip: 'VIP ဖြစ်လာပါ',
  play_the_way_you_want: 'သင့်စိတ်ကြိုက် ကစားပါ',
  join_vip_program: 'ယခု VIP ပရိုဂရမ်တွင် ပါဝင်ပါ!',
  join_now: 'ယခုပါဝင်ပါ',
  vip_club: 'ဗွီအိုင်ပီ ကလပ်',
  withdraw_limit: 'ထုတ်ယူမှု ကန့်သတ်ချက်',
  cashback: 'ငွေပြန်အမ်း',
  personal_account_manager: 'ကိုယ်ပိုင် အကောင့်မန်နေဂျာ',
  dont_show_again: 'နောက်တစ်ခါပြမည်မဟုတ်ပါ',
  next: 'နောက်တစ်ခု',
  close: 'ပိတ်သည်',
};

export default my;
