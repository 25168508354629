import PropTypes from 'prop-types';
import { Box, Button, Typography, Stack } from '@mui/material';
import QRCode from 'react-qr-code';

import CustomModal from '../../../../components/CustomModal';
import Image from '../../../../components/Image';
import useLocales from '../../../../hooks/useLocales';
import { useDispatch } from '../../../../redux/store';
import { openTransactionsDialog } from '../../../../redux/slices/transactionsDialog';

const PopupQrCode = ({ qrCodeString, setTopPayQrCode }) => {
  const { translate } = useLocales();

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(openTransactionsDialog({ open: false, isDeposit: true }));
    setTopPayQrCode(null);
  };

  return (
    <CustomModal open={!!qrCodeString} onClose={() => handleClose()}>
      <Box sx={{ p: 2, background: 'rgb(76, 184, 169)' }}>
        <Stack direction="row" alignItems={'center'} justifyContent="center" spacing={1 / 2}>
          <Typography sx={{ fontWeight: 600, fontSize: { sm: '1rem', xs: '2.7vw' } }}>
            {translate('please_scan_using_your')}
          </Typography>
          <Image
            sx={{ width: { sm: '65px', xs: '11vw' }, borderRadius: '5px' }}
            src={require('../../../../assets/deposit/pix.jpg')}
          />
          <Typography sx={{ fontWeight: 600, fontSize: { sm: '1rem', xs: '2.7vw' } }}>app</Typography>
        </Stack>

        <Box
          sx={{
            background: 'white',
            p: 2,
            height: 'auto',
            my: { sm: 5, xs: 2 },
            mx: 'auto',
            maxWidth: 200,
            width: '100%',
            borderRadius: 2,
          }}
        >
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={qrCodeString}
            viewBox={`0 0 256 256`}
          />
        </Box>
        <Button
          onClick={() => handleClose()}
          sx={{
            display: 'block',
            mx: 'auto',
            mb: { sm: 2, xs: 1 },
            background: '#fff',
            '&:hover': {
              bgcolor: '#fff',
            },
          }}
        >
          {translate('ok')}
        </Button>
      </Box>
    </CustomModal>
  );
};

PopupQrCode.propTypes = {
  qrCodeString: PropTypes.string,
  setTopPayQrCode: PropTypes.func,
};

export default PopupQrCode;
