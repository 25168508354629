import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer, Typography } from '@mui/material';
// hooks
import conObj from '../../../utils/connection-assistant';
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
import palette from '../../../theme/palette';
import Image from '../../../components/Image';
import Iconify from '../../../components/Iconify';
//
import navConfig from './NavConfig';
import NavbarDocs from './NavbarDocs';
import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';
import useRedirect from '../../../hooks/useRedirect';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const navigate = useRedirect();

  const { translate } = useLocales();

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack direction={'column'} justifyContent={'space-between'} flex={1} sx={{ height: '100%' }}>
        <Box>
          <Stack
            spacing={3}
            sx={{
              p: 1,
              flexShrink: 0,
              mt: 1,
              // mb: 1,
              ...(isCollapse && { alignItems: 'center' }),
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="end" sx={{ width: '100%' }}>
              {!isDesktop && (
                <>
                  <Stack flex={1} direction="row" alignItems="center" spacing={0.5} pl={1}>
                    <Box onClick={() => navigate('/')}>
                      <Image
                        src={require('../../../assets/brand-logo-icon.png')}
                        alt=""
                        sx={{
                          cursor: 'pointer',
                          width: '65px',
                          height: '30px',
                          '& img': { maxHeight: '9.5vh', width: 'auto' },
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontWeight: 900,
                        background: 'linear-gradient(90deg, #FFD700, #FFA500)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        display: 'inline-block',
                        fontSize: 18,
                      }}
                    >
                      BERRY96
                    </Typography>
                  </Stack>
                  <CollapseButton onToggleCollapse={onCloseSidebar} collapseClick={!isOpenSidebar} />
                </>
              )}
            </Stack>
          </Stack>
          <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} onCloseSidebar={onCloseSidebar} />
        </Box>
        <Box
          m={1}
          mb={2}
          p={1}
          sx={{
            background: 'linear-gradient(to bottom, #470f0f 0%, #000 100%)',
            border: '1px solid #f2c556',
            borderRadius: '8px',
            cursor: 'pointer',
          }}
        >
          <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
            <Iconify icon={'bi:headset'} color="#FFD700" width={25} height={25} />
            <Typography
              sx={{
                background: 'linear-gradient(90deg, #FFD700, #FFA500)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontSize: { xs: 18, lg: 15 },
                margin: 0,
                height: 'fit-content',
                fontWeight: 900,
              }}
            >
              24/7 {translate('live_chat')}
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: NAVBAR.DASHBOARD_WIDTH,
        ...(collapseClick && {
          position: 'absolute',
        }),
        backgroundColor: '#FFF',
      }}
    >
      {!isDesktop && (
        <>
          {isOpenSidebar && <Box className="mobile-nav-backdrop" />}
          <Drawer
            variant="persistent"
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            PaperProps={{
              sx: {
                width: NAVBAR.DASHBOARD_WIDTH,
                background: 'linear-gradient(to bottom, rgba(30, 30, 30, 1) 0%, rgba(0, 0, 0, 1) 100%)',
              },
            }}
          >
            {renderContent}
          </Drawer>
        </>
      )}

      {isDesktop && (
        <Drawer
          open={isOpenSidebar}
          // onClose={onCloseSidebar}
          variant="persistent"
          hideBackdrop={true}
          // onMouseEnter={onHoverEnter}
          // onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              height: 'calc(100% - 64px)',
              top: 'unset',
              bottom: 0,
              width: NAVBAR.DASHBOARD_WIDTH,
              px: 1,
              pt: 0,
              background: 'linear-gradient(to bottom, rgba(30, 30, 30, 1) 0%, rgba(0, 0, 0, 1) 100%)',
              boxShadow: '0 1px 2px -2px rgba(0,0,0,.64),0 3px 6px 0 rgba(0,0,0,.48),0 5px 12px 4px rgba(0,0,0,.36)',
              border: 'none',
              boxSizing: 'border-box',
              visibility: 'unset !important',
              transform: 'unset !important',
              zIndex: 1100,
              transition: (theme) =>
                theme.transitions.create('margin', {
                  duration: theme.transitions.duration.enteringScreen,
                  easing: theme.transitions.easing.easeInOut,
                }),
              ...(isCollapse && {
                // width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
                marginLeft: '-240px',
              }),
              // ...(collapseHover && {
              //   ...cssStyles(theme).bgBlur(),
              //   boxShadow: (theme) => theme.customShadows.z24,
              // }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
