import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Grid, ListSubheader, Stack, Typography, LinearProgress } from '@mui/material';
import { useParams } from 'react-router';
import Image from '../../Image';
// hooks
import useLocales from '../../../hooks/useLocales';

import { useDispatch, useSelector } from '../../../redux/store';
import { setActiveTab } from '../../../redux/slices/game';
import { openLoginSignup } from '../../../redux/slices/authPages';

import useResponsive from '../../../hooks/useResponsive';
import Iconify from '../../Iconify';
import palette from '../../../theme/palette';
import LanguagePopover from '../../../layouts/dashboard/header/LanguagePopover';
import useRedirect from '../../../hooks/useRedirect';
import useAuth from '../../../hooks/useAuth';
import getPercentage from '../../../utils/getPercentage';
import { fCurrency } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
  onCloseSidebar: PropTypes.func,
};

const ButtonContainer = styled(Stack)(({ theme }) => ({
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  height: '43px',
  alignItems: 'center',
  cursor: 'pointer',
}));

const NavWrapper = styled(Box)(({ theme }) => ({
  justifyContent: 'center',
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '10px',
  cursor: 'pointer',
  paddingTop: 3,
  paddingBottom: 3,
  position: 'relative',
  height: '100%',
}));

const NavBtnText = styled(Typography)(() => ({
  fontSize: '11.926px',
  fontWeight: 700,
  fontFamily: 'sans-serif',
  lineHeight: '1.5',
  textAlign: 'center',
  textDecoration: 'none',
  color: palette.navBarVertical.menu.color,
}));

export default function NavSectionVertical({ navConfig, isCollapse = false, onCloseSidebar = () => {}, ...other }) {
  const dispatch = useDispatch();

  const navigate = useRedirect();

  const { translate } = useLocales();

  const params = useParams();

  const { gameCategsAndCompanies, angPauPromos, withdrawRequirement } = useSelector((x) => ({
    ...x.game,
    ...x.lookup,
  }));

  const isDesktop = useResponsive('up', 'lg');
  const { isAuthenticated } = useAuth();

  const makeGameCategory = (_category) => {
    let nav = '';

    if (_category?.slug.toLowerCase().indexOf('slots') !== -1)
      nav = { src: require('../../../assets/menu/nav-slot.png') };

    if (_category?.slug.toLowerCase().indexOf('live-casino') !== -1)
      nav = { src: require('../../../assets/menu/nav-live.png') };

    if (_category?.slug.toLowerCase().indexOf('fishing') !== -1)
      nav = { src: require('../../../assets/menu/nav-fish.png') };

    if (_category?.slug.toLowerCase().indexOf('card') !== -1)
      nav = { src: require('../../../assets/menu/nav-card.png') };

    if (_category?.slug.toLowerCase().indexOf('sports') !== -1)
      nav = { src: require('../../../assets/menu/nav-sports.png') };

    if (_category?.slug.toLowerCase().indexOf('e-sports') !== -1)
      nav = { src: require('../../../assets/menu/nav-e-sports.png') };

    if (_category?.slug.toLowerCase().indexOf('instant-game') !== -1) nav = { icon: 'ph:lightning-fill' };

    return (
      <NavWrapper
        key={_category?.key}
        onClick={() => {
          dispatch(setActiveTab(_category?.slug));
          if (!isDesktop) onCloseSidebar();
          if (window.location.pathname !== '/') navigate('/');
        }}
        sx={{
          background: 'linear-gradient(to bottom, #470f0f 0%, #000 100%)',
          border: '1px solid #f2c556',
        }}
      >
        {nav?.src ? (
          <Box sx={{ width: '35px', height: '35px', display: 'flex', alignItems: 'center' }}>
            <Image
              src={nav.src}
              sx={{
                height: '30px',
                width: '30px',
                mx: 'auto',
                cursor: 'pointer',
                '& img': {
                  maxHeight: '30px',
                },
              }}
            />
          </Box>
        ) : (
          <></>
        )}
        {nav?.icon ? (
          <Iconify
            icon={nav.icon}
            height={25}
            sx={{ width: '35px', height: '25px !important', maxHeight: '25px !important', color: 'red' }}
          />
        ) : (
          <></>
        )}
        {/* <NavBtnText>{translate(_category?.name?.toLowerCase().replace(/[\s-]+/g, '_'))}</NavBtnText> */}
        <NavBtnText>{_category?.name}</NavBtnText>
      </NavWrapper>
    );
  };

  return (
    <Box {...other}>
      <Stack direction="column" spacing={1} sx={{ p: 1, borderRadius: 1, mb: 1 }}>
        {angPauPromos?.data?.length ? (
          <ButtonContainer
            direction="row"
            sx={{
              backgroundImage: `url(${require('../../../assets/menu/cash-claim.png')})`,
            }}
            onClick={() => navigate('angpau')}
          >
            <Typography sx={{ color: 'white', fontWeight: 700, ml: '30%', fontSize: '13px' }}>
              {translate('cash_claim')}!
            </Typography>
          </ButtonContainer>
        ) : (
          ''
        )}

        {(withdrawRequirement?.calculation_type === 'turnover' ||
          withdrawRequirement?.calculation_type === 'winover') &&
          withdrawRequirement?.requirement !== 0 && (
            <Stack direction="column" sx={{ p: 1, border: '1px solid #f2c556', borderRadius: 2 }}>
              <LinearProgress
                variant="determinate"
                value={getPercentage(withdrawRequirement?.current, withdrawRequirement?.requirement)}
              />
              <Typography sx={{ fontSize: '.75rem', color: '#fff' }}>
                {`${
                  withdrawRequirement?.calculation_type === 'turnover' ? translate('turnover') : translate('balance')
                } ${translate('requirement')}`}
                :{' '}
              </Typography>
              <Typography sx={{ fontSize: '.75rem', color: '#fff', lineHeight: 1 }}>
                {fCurrency(withdrawRequirement?.current)} / {fCurrency(withdrawRequirement?.requirement)}
              </Typography>
            </Stack>
          )}
        <Box>
          <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
            <Grid item xs={6}>
              <NavWrapper
                sx={{
                  background: 'linear-gradient(to bottom, #470f0f 0%, #000 100%)',
                  border: '1px solid #f2c556',
                }}
                onClick={() => {
                  dispatch(setActiveTab('Popular'));
                  if (!isDesktop) onCloseSidebar();
                  if (window.location.pathname !== '/home') navigate('/');
                }}
              >
                <Box sx={{ width: '35px', height: '35px', display: 'flex', alignItems: 'center' }}>
                  <Image
                    src={require('../../../assets/menu/popular.png')}
                    sx={{ cursor: 'pointer', width: 'auto', height: '30px', mx: 'auto' }}
                  />
                </Box>
                <NavBtnText>{translate('popular')}</NavBtnText>
              </NavWrapper>
            </Grid>
            {gameCategsAndCompanies.length > 0 &&
              gameCategsAndCompanies.map((item, index) => (
                <Grid key={index} item xs={6}>
                  {makeGameCategory(item)}
                </Grid>
              ))}
            {/* <Grid item xs={6}>
              <NavWrapper>
                <Iconify icon={'ion:game-controller'} sx={{ width: '100%', height: '25px' }} />
                <NavBtnText>{translate('Demo')}</NavBtnText>
              </NavWrapper>
            </Grid>
            <Grid item xs={6}>
              <NavWrapper>
                <Box sx={{ width: '35px', height: '35px', display: 'flex', alignItems: 'center' }}>
                  <Image
                    src={require('../../../assets/menu/nav-fave.png')}
                    sx={{
                      cursor: 'pointer',
                      width: 'auto',
                      mx: 'auto',
                      '& img': {
                        maxHeight: '25px',
                      },
                    }}
                  />
                </Box>
                <NavBtnText>{translate('Favorites')}</NavBtnText>
              </NavWrapper>
            </Grid> */}
          </Grid>
        </Box>

        {/* <MusicPlayer /> */}

        <NavWrapper sx={{ px: 1, py: 1 }}>
          <Stack sx={{ width: '100%' }} spacing={0.8}>
            <ButtonContainer
              onClick={() => navigate('/promotion')}
              direction="row"
              sx={{
                backgroundImage: `url(${require('../../../assets/menu/bonus.png')})`,
              }}
            >
              <Image
                src={require('../../../assets/menu/bonus_icon.png')}
                sx={{
                  width: '30px',
                  height: '30px',
                  pl: 1,
                  img: {
                    objectFit: 'contain',
                  },
                }}
              />
              <Typography sx={{ color: 'white', fontWeight: 700, ml: 1, fontSize: '13px' }}>
                {translate('promotion')}
              </Typography>
            </ButtonContainer>
            {/* <Image src={require('../../../assets/menu/bonus.png')} sx={{ width: '100%', height: 'auto' }} /> */}
            <ButtonContainer
              onClick={() =>
                isAuthenticated ? navigate('/referral') : dispatch(openLoginSignup({ open: true, isLogin: true }))
              }
              direction="row"
              sx={{
                backgroundImage: `url(${require('../../../assets/menu/refer.png')})`,
              }}
            >
              <Image
                src={require('../../../assets/menu/refer_icon.png')}
                sx={{
                  width: '30px',
                  height: '30px',
                  pl: 1,
                  img: {
                    objectFit: 'contain',
                  },
                }}
              />
              <Typography sx={{ color: 'white', fontWeight: 700, ml: 1, fontSize: '13px' }}>
                {translate('refer_and_reward')}
              </Typography>
            </ButtonContainer>
            <ButtonContainer
              direction="row"
              sx={{
                backgroundImage: `url(${require('../../../assets/menu/vip.png')})`,
              }}
              onClick={() =>
                isAuthenticated ? navigate('/vip') : dispatch(openLoginSignup({ open: true, isLogin: true }))
              }
            >
              <Typography sx={{ color: 'white', fontWeight: 700, ml: '25%', fontSize: '13px' }}>
                {translate('VIP')}
              </Typography>
            </ButtonContainer>
            {/* <ButtonContainer
              direction="row"
              sx={{
                backgroundImage: `url(${require('../../../assets/menu/daily-mission.png')})`,
              }}
            >
              <Typography sx={{ color: 'white', fontWeight: 700, ml: '25%', fontSize: '13px' }}>
                {translate('daily_mission')}
              </Typography>
            </ButtonContainer>
            <ButtonContainer
              direction="row"
              sx={{
                backgroundImage: `url(${require('../../../assets/menu/vip.png')})`,
              }}
              onClick={() =>
                isAuthenticated ? navigate('/vip') : dispatch(openLoginSignup({ open: true, isLogin: true }))
              }
            >
              <Typography sx={{ color: 'white', fontWeight: 700, ml: '25%', fontSize: '13px' }}>
                {translate('VIP')}
              </Typography>
            </ButtonContainer>
            <ButtonContainer
              direction="row"
              sx={{
                backgroundImage: `url(${require('../../../assets/menu/agent.png')})`,
              }}
              onClick={() =>
                isAuthenticated ? navigate('/agent') : dispatch(openLoginSignup({ open: true, isLogin: true }))
              }
            >
              <Typography sx={{ color: 'white', fontWeight: 700, ml: '25%', fontSize: '13px' }}>
                {translate('agent')}
              </Typography>
            </ButtonContainer> */}
          </Stack>
        </NavWrapper>
        {/* <Image src={require('../../../assets/menu/refer.png')} sx={{ width: '100%', height: 'auto' }} /> */}
        <Box sx={{ pl: 1 }}>
          {isAuthenticated ? (
            <>
              <NavBottomBtn
                onClick={() => {
                  navigate('/history/bet');
                  if (!isDesktop) onCloseSidebar();
                }}
                title={translate('game_history')}
                icon={'solar:history-outline'}
              />
              <NavBottomBtn
                onClick={() => {
                  navigate('/history/transaction');
                  if (!isDesktop) onCloseSidebar();
                }}
                title={translate('transaction_history')}
                icon={'la:exchange-alt'}
              />
            </>
          ) : (
            <></>
          )}
          {/* <NavBottomBtn
            title={translate('Contact Us')}
            icon={'fluent:person-support-24-regular'}
          />
          <NavBottomBtn title={translate('FAQ')} icon={'octicon:question-24'} />
          <NavBottomBtn title={translate('Download')} icon="solar:download-linear" /> */}
          {/* <CountryPopover
            CustomBtn={<NavBottomBtn icon="octicon:globe-24" />}
            PopoverProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              disabledArrow: true,
            }}
          />
          {params?.domain === 'myr' && (
            <LanguagePopover
              CustomBtn={<NavBottomBtn icon="mdi:language" />}
              PopoverProps={{
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                disabledArrow: true,
              }}
            />
          )} */}
        </Box>
      </Stack>
    </Box>
  );
}

const NavBottomBtn = ({ icon, image, title, onClick = () => {} }) => (
  <Box
    onClick={onClick}
    sx={{
      justifyContent: 'flex-start',
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '10px',
      cursor: 'pointer',
      py: '5px',
      position: 'relative',
    }}
  >
    {icon ? <Iconify icon={icon} sx={{ width: '24px', height: '24px', mr: '13px', mb: 0, color: '#FFEC00' }} /> : <></>}
    {image ? (
      <Box sx={{ width: '24px', height: '24px', mr: '13px', mb: 0, color: '#fff' }}>
        <Image src={image} />
      </Box>
    ) : (
      <></>
    )}
    <Typography
      sx={{
        fontSize: '13px',
        fontWeight: 100,
        fontFamily: 'sans-serif',
        lineHeight: '1.5',
        textAlign: 'left',
        textDecoration: 'none',
        color: '#fff',
      }}
    >
      {title}
    </Typography>
  </Box>
);
