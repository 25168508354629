import { useState, useEffect } from 'react';

export default function useCountdown(date, timezone = Intl.DateTimeFormat().resolvedOptions().timeZone) {
  const [countdown, setCountdown] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    const interval = setInterval(() => setNewTime(), 1000);
    return () => clearInterval(interval);
  }, [date, timezone]);

  const setNewTime = () => {
    const startTime = new Date(date);
    const endTime = new Date().toLocaleString('en-US', { timeZone: timezone });
    const distanceToNow = startTime - new Date(endTime);

    let getDays = Math.floor(distanceToNow / (1000 * 60 * 60 * 24));
    let getHours = Math.floor((distanceToNow % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let getMinutes = Math.floor((distanceToNow % (1000 * 60 * 60)) / (1000 * 60));
    let getSeconds = Math.floor((distanceToNow % (1000 * 60)) / 1000);

    // Handle null, empty, or NaN values
    getDays = Number.isNaN(getDays) || getDays < 0 ? 0 : getDays;
    getHours = Number.isNaN(getHours) || getHours < 0 ? 0 : getHours;
    getMinutes = Number.isNaN(getMinutes) || getMinutes < 0 ? 0 : getMinutes;
    getSeconds = Number.isNaN(getSeconds) || getSeconds < 0 ? 0 : getSeconds;

    setCountdown({
      days: getDays.toString().padStart(3, '0'),
      hours: getHours.toString().padStart(2, '0'),
      minutes: getMinutes.toString().padStart(2, '0'),
      seconds: getSeconds.toString().padStart(2, '0'),
    });
  };

  return countdown;
}
