import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography, Stack, Grid } from '@mui/material';
// components
import Image from '../../components/Image';
// sections
import { ChangePasswordForm } from '../../sections/auth/change-password';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

const ContentStyle = styled(Stack)(({ theme }) => ({
  // maxWidth: 480,
  // minHeight: '100vh',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2),
  background: 'linear-gradient(to bottom, rgba(30, 30, 30, 1) 0%, rgba(0, 0, 0, 1) 100%)'
}));

// ----------------------------------------------------------------------

export default function ChangePassword() {
  // const { currentTab, setCurrentTab } = useTabs('0');
  const { translate } = useLocales();

  return (
    <ContentStyle spacing={4}>
      <Typography className="gold-text" variant="h4" fontWeight={900}>
        {translate('change_password')}
      </Typography>

      <ChangePasswordForm />
    </ContentStyle>
  );
}
