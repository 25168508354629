import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Modal, Stack, Typography } from '@mui/material';
import { getIdToken } from 'firebase/auth';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../../components/Iconify';
import useLocales from '../../../../hooks/useLocales';
import firebaseUtil from '../../../../utils/firebaseUtil';
import useRedirect from '../../../../hooks/useRedirect';
import PhonenumberTextField from '../../../../components/PhonenumberTextField';
import RegisterOTPForm from '../visitor/RegisterOTPForm';
import { getOtpErrorMessage } from '../../../../utils/formatString';

const snackbarOptionsSuccess = { variant: 'success', autoHideDuration: 5000 };
const snackbarOptionsError = { variant: 'error', autoHideDuration: 5000 };

const ForgotPasswordDialog = ({ openForgotPasswordDialog, setOpenForgotPasswordDialog }) => {
  const navigate = useRedirect();

  const { translate } = useLocales();

  const [phoneNumber, setPhoneNumber] = useState('');

  const [error, setError] = useState('');

  const [isInit, setIsInit] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [verification, setVerification] = useState({
    number: null,
    id: null,
    otpSent: false,
    isVerified: false,
  });

  useEffect(() => {
    firebaseUtil.initVerifier();
    setIsInit(firebaseUtil.getIsInitialized());
  }, []);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      if (verification?.id) {
        const confirmId = await firebaseUtil.handleVerifyOTP(verification?.id, verification?.otp);

        const idToken = await getIdToken(confirmId.user);

        setVerification((prev) => ({ ...prev, isVerified: true }));

        navigate(`/auth/reset-password`, { state: { idToken } });
      }
    } catch (error) {
      throw new Error(getOtpErrorMessage(translate, error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={openForgotPasswordDialog}
      onClose={() => setOpenForgotPasswordDialog(false)}
      sx={{ overflow: 'hidden' }}
    >
      <Box
        sx={{
          background: 'linear-gradient(to bottom, rgba(30, 30, 30, 1) 0%, rgba(0, 0, 0, 1) 100%)',
          border: '1px solid #f2c556',
          borderRadius: '18px',
          height: 'auto',
          width: '96vw',
          maxWidth: '500px',
          overflow: 'auto',
          p: '50px 24px 24px',
          m: 'auto',
          boxShadow: '0 1px 2px -2px rgba(0,0,0,.64),0 3px 6px 0 rgba(0,0,0,.48),0 5px 12px 4px rgba(0,0,0,.36)',
          position: 'relative',
          marginTop: '50px',
        }}
        className="modal-container container-no-scrollbar"
      >
        <Iconify
          icon={'ep:close'}
          width={24}
          height={24}
          sx={{ cursor: 'pointer', position: 'absolute', right: 16, top: 16, color: 'rgb(147, 172, 211)' }}
          onClick={() => setOpenForgotPasswordDialog(false)}
        />

        <Typography sx={{ fontWeight: 600, color: '#f2c556', fontSize: '18px', mb: 2 }}>Reset Password</Typography>
        <Box>
          <Box sx={{ mb: 2 }}>
            <Stack alignItems="center">
              <PhonenumberTextField
                name="userPhoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e)}
                fullWidth
                sx={{ color: 'grey', borderColor: '#FF1500' }}
              />
              {error && <span style={{ fontSize: '.75rem', color: '#FF1500' }}>{error}</span>}

              <RegisterOTPForm
                isForgot={true}
                phoneNumber={phoneNumber}
                setVerification={setVerification}
                verification={verification}
              />
            </Stack>
          </Box>

          <LoadingButton loading={isLoading} variant="contained" sx={{ width: '100%' }} onClick={() => onSubmit()}>
            {translate(`confirm`)}
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

ForgotPasswordDialog.propTypes = {
  openForgotPasswordDialog: PropTypes.bool,
  setOpenForgotPasswordDialog: PropTypes.func,
};

export default ForgotPasswordDialog;
